import React, { useEffect, useState } from 'react';
import { TabTitle } from '../../GeneralFunction';

import './edExterior.css';


import img223 from '../../../img/Exterior Render pic/1st-image_49855682446_o.jpg'
import img224 from '../../../img/Exterior Render pic/1st_view_49620507773_o.jpg'
import img227 from '../../../img/Exterior Render pic/2nd-image_49855988187_o.jpg'
import img228 from '../../../img/Exterior Render pic/2nd-view_49914993236_o.jpg'
import img229 from '../../../img/Exterior Render pic/2nd_image_49621020821_o.jpg'
import img230 from '../../../img/Exterior Render pic/30624266_2065494227025613_649921339553621293_n_42912652155_o.jpg'
import img231 from '../../../img/Exterior Render pic/36512450_2118922578349444_6161316248958271488_n_28879855097_o.jpg'
import img233 from '../../../img/Exterior Render pic/3d-view_49621031361_o.jpg'
import img234 from '../../../img/Exterior Render pic/3d-visualization_49621233137_o.jpg'
import img235 from '../../../img/Exterior Render pic/3rd-view_49705447662_o.jpg'
import img236 from '../../../img/Exterior Render pic/4444444444_43933031860_o.jpg'
import img240 from '../../../img/Exterior Render pic/8th-view-1_49620488673_o.jpg'
import img241 from '../../../img/Exterior Render pic/Aloffr20 F_4.jpg'
import img245 from '../../../img/Exterior Render pic/Aymaneidi 4th.jpg'
import img247 from '../../../img/Exterior Render pic/Aymaneidi Final_1.jpg'
import img249 from '../../../img/Exterior Render pic/Barrabee 2nd version.jpg'
import img250 from '../../../img/Exterior Render pic/bashundhara3_30743150737_o.jpg'
import img251 from '../../../img/Exterior Render pic/borisal_44959013804_o.jpg'
import img252 from '../../../img/Exterior Render pic/BeeHomes  Exterior1.jpg'
import img254 from '../../../img/Exterior Render pic/Bijjujn L_2.jpg'
import img255 from '../../../img/Exterior Render pic/Blakedog EXT_1.jpg'
import img257 from '../../../img/Exterior Render pic/Bostonbomber18 Final Image2.jpg'
import img258 from '../../../img/Exterior Render pic/Broggy 1st.jpg'
import img260 from '../../../img/Exterior Render pic/Delpops 1.jpg'
import img262 from '../../../img/Exterior Render pic/Delpops 5.jpg'
import img264 from '../../../img/Exterior Render pic/Derekgaudet603 1st version.jpg'
import img266 from '../../../img/Exterior Render pic/Eniomaiale View_1.jpg'
import img267 from '../../../img/Exterior Render pic/EthanB 632 __8 house 6_front.jpg'
import img269 from '../../../img/Exterior Render pic/EthanB 632 __8 house 8_ front1.jpg'
import img270 from '../../../img/Exterior Render pic/Ethanb.632 2.jpg'
import img274 from '../../../img/Exterior Render pic/faisal_30743148137_o.jpg'
import img275 from '../../../img/Exterior Render pic/final-duplex-project_45633254282_o.jpg'
import img276 from '../../../img/Exterior Render pic/final-image_49705418612_o.jpg'
import img277 from '../../../img/Exterior Render pic/final-view_49621284712_o.jpg'
import img279 from '../../../img/Exterior Render pic/for-freelancer-copy_49620998446_o.jpg'
import img280 from '../../../img/Exterior Render pic/front-final-close_49705121546_o.jpg'
import img281 from '../../../img/Exterior Render pic/Fitnesselite 1st Image.jpg'
import img282 from '../../../img/Exterior Render pic/Fitnesselite 2nd view.jpg'
import img284 from '../../../img/Exterior Render pic/Fivesquid 3rd_Final.jpg'
import img285 from '../../../img/Exterior Render pic/Fivesquidevening.jpg'
import img286 from '../../../img/Exterior Render pic/Free work View_1.jpg'
import img289 from '../../../img/Exterior Render pic/glorious-garden_30743146997_o.jpg'
import img291 from '../../../img/Exterior Render pic/Gamesgid v2.jpg'
import img292 from '../../../img/Exterior Render pic/Gamesgid v3.jpg'
import img298 from '../../../img/Exterior Render pic/Guv B First Image.jpg'
import img300 from '../../../img/Exterior Render pic/Haroon Iqbal 3rd version.jpg'
import img301 from '../../../img/Exterior Render pic/Herrestates 1st.png'
import img302 from '../../../img/Exterior Render pic/Herrestates 2nd.jpg'
import img303 from '../../../img/Exterior Render pic/Herrestates 3.jpg'
import img304 from '../../../img/Exterior Render pic/Himalchuhan F_1.jpg'
import img305 from '../../../img/Exterior Render pic/i-will-create-3d-rendering-architecture-project-for-real-estate_49621254502_o.jpg'
import img306 from '../../../img/Exterior Render pic/image.png'
import img307 from '../../../img/Exterior Render pic/Interior 1st.jpg'
import img311 from '../../../img/Exterior Render pic/Jacalbarracin 1st8.jpg'
import img312 from '../../../img/Exterior Render pic/Jacalbarracin 1stt.jpg'
import img315 from '../../../img/Exterior Render pic/Jacalbarracin 2ndt.jpg'
import img320 from '../../../img/Exterior Render pic/Jacalbarracin Front view...jpg'
import img321 from '../../../img/Exterior Render pic/Jacalbarracin Front_day.jpg'
import img322 from '../../../img/Exterior Render pic/Jacalbarracin View_3.jpg'
import img325 from '../../../img/Exterior Render pic/James_smith001 1sts.jpg'
import img326 from '../../../img/Exterior Render pic/Jamesdeanuk 1st view.jpg'
import img327 from '../../../img/Exterior Render pic/Jamesdeanuk 2nd view .jpg'
import img328 from '../../../img/Exterior Render pic/Jamesdeanuk 3rd View .jpg'
import img331 from '../../../img/Exterior Render pic/Jayvarmal123     1_4 - Photo.jpg'
import img332 from '../../../img/Exterior Render pic/Jayvarmal123    1_1 - Photo.jpg'
import img333 from '../../../img/Exterior Render pic/Jayvarmal123 1.jpg'
import img334 from '../../../img/Exterior Render pic/Jayvarmal123 1st.jpg'
import img336 from '../../../img/Exterior Render pic/Jayvarmal123 back day.jpg'
import img337 from '../../../img/Exterior Render pic/Jayvarmal123 back night.jpg'
import img340 from '../../../img/Exterior Render pic/Jcalbarracin3_ Exterior1.jpg'
import img345 from '../../../img/Exterior Render pic/Jonescopl 2ND.jpg'
import img348 from '../../../img/Exterior Render pic/Jonjon 2005   1st vIew.jpg'
import img352 from '../../../img/Exterior Render pic/Joybooker Front2.jpg'
import img353 from '../../../img/Exterior Render pic/kangaroo UK 3rd1 (2).jpg'
import img355 from '../../../img/Exterior Render pic/Ladyschourup 1.jpg'
import img358 from '../../../img/Exterior Render pic/Lambo exterior D.jpg'
import img359 from '../../../img/Exterior Render pic/musfika-maliha-final11111_31811366358_o.jpg'
import img360 from '../../../img/Exterior Render pic/Marketflorida 2nd.jpg'
import img361 from '../../../img/Exterior Render pic/Matthewbaldassa Back_1.jpg'
import img363 from '../../../img/Exterior Render pic/MatthewbaldassaFront_1.jpg'
import img364 from '../../../img/Exterior Render pic/Mikicar1978 Night.jpg'
import img365 from '../../../img/Exterior Render pic/Mikicar1978_2nd Terrace.jpg'
import img366 from '../../../img/Exterior Render pic/Millenia95 2nd submit.jpg'
import img367 from '../../../img/Exterior Render pic/Mohassan162 1st.jpg'
import img372 from '../../../img/Exterior Render pic/nilphamari1111-copy_31811376628_o.jpg'
import img373 from '../../../img/Exterior Render pic/north-east-view_49621309287_o.jpg'
import img378 from '../../../img/Exterior Render pic/Pamma83 Front_3.jpg'
import img380 from '../../../img/Exterior Render pic/Pharmtect View_1.jpg'
import img382 from '../../../img/Exterior Render pic/Phil T 2nd.....jpg'
import img383 from '../../../img/Exterior Render pic/Phil T 4th.jpg'
import img384 from '../../../img/Exterior Render pic/PPH project 1st views.jpg'
import img385 from '../../../img/Exterior Render pic/regencyu-market_31811373198_o.jpg'
import img386 from '../../../img/Exterior Render pic/restaurent-ext_30743135897_o.jpg'
import img387 from '../../../img/Exterior Render pic/Rchaparro2000 Final Design.jpg'
import img388 from '../../../img/Exterior Render pic/Rchaparro2000 First view.jpg'
import img391 from '../../../img/Exterior Render pic/sanaullah2_31811369578_o.jpg'
import img395 from '../../../img/Exterior Render pic/st-angle-view_49915292342_o.jpg'
import img396 from '../../../img/Exterior Render pic/Sarahgibson 3rd.jpg'
import img416 from '../../../img/Exterior Render pic/Sinthusen 4th.jpg'
import img418 from '../../../img/Exterior Render pic/Sinthusen Final_3.jpg'
import img419 from '../../../img/Exterior Render pic/Sree 1st.jpg'
import img420 from '../../../img/Exterior Render pic/Stephenstewa248 Final version.jpg'
import img421 from '../../../img/Exterior Render pic/Stephenstewa248 Final_View_1.jpg'
import img422 from '../../../img/Exterior Render pic/Stephenstewa248 Final_View_2.jpg'
import img423 from '../../../img/Exterior Render pic/Studio View_3.jpg'
import img424 from '../../../img/Exterior Render pic/taher-kaka2_49620496518_o.jpg'
import img425 from '../../../img/Exterior Render pic/Tanjila 1st.jpg'
import img432 from '../../../img/Exterior Render pic/Westfisher v2.jpg'
import img435 from '../../../img/375 dorchester road, weymouth/Plot 1_2.jpg'
import img438 from '../../../img/375 dorchester road, weymouth/View_1.jpg'
import img441 from '../../../img/Anthony Papadopoulos/Front_1.jpg'
import img442 from '../../../img/Anthony Papadopoulos/Front_2.jpg'
import img443 from '../../../img/Anthony Papadopoulos/Front_3.jpg'
import img444 from '../../../img/APPLEGREEN HOMES/GOWERS STREET_1.jpg'
import img445 from '../../../img/APPLEGREEN HOMES/GOWERS STREET_3.jpg'
import img446 from '../../../img/BHAGWANT SINGH SANDHU/12621,89A AVENUE,Night.jpg'
import img448 from '../../../img/Creative Home design/Back.jpg'
import img449 from '../../../img/Creative Home design/Front View.jpg'
import img450 from '../../../img/isiimmobilien/Front.png'
import img451 from '../../../img/Jay Verma/V2.jpg'
import img452 from '../../../img/Jay Verma/View_1.jpg'
import img453 from '../../../img/JC Albarracin     A1 Design and Construction Ltd. Co/23814 Andora Ridge.jpg'
import img454 from '../../../img/JC Albarracin     A1 Design and Construction Ltd. Co/23814 Andora Ridgev2.jpg'
import img455 from '../../../img/JC Albarracin     A1 Design and Construction Ltd. Co/24318 CANYON ROW.jpg'
import img456 from '../../../img/JC Albarracin     A1 Design and Construction Ltd. Co/Empress Crossing dr.jpg'
import img457 from '../../../img/Lee Vincent High Park Homes/Guilsford.jpg'
import img458 from '../../../img/Lee Vincent High Park Homes/Surbiton.jpg'
import img460 from '../../../img/Lee Vincent High Park Homes/Surbiton_3.jpg'
import img461 from '../../../img/leightonjames1/The Avenue Yatton.jpg'
import img462 from '../../../img/leightonjames1/The Croft_1.jpg'
import img463 from '../../../img/leightonjames1/The Croft_2.jpg'
import img464 from '../../../img/2 Lumley road.jpg'
import img467 from '../../../img/2 Lumley road_4.jpg'
import img468 from '../../../img/154 Eastcote Roadv2.jpg'
import img470 from '../../../img/1009 N. Ind.jpg'
import img476 from '../../../img/AldersgateRoadTop.jpg'
import img480 from '../../../img/Dinobia.jpg'
import img481 from '../../../img/Ext_1.jpg'
import img485 from '../../../img/Front_Exterior.jpg'
import img488 from '../../../img/RICK_1.jpg'
import img489 from '../../../img/RICK_2.jpg'

import ClipLoader from "react-spinners/ClipLoader";
import CloseIcon from '@mui/icons-material/Close';



const Exterior = () => {
    TabTitle('3d-Exterior - Arch Pixel');
    

    let imageData = [
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img223,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img224,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img227
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img228,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img229,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img230,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img231,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img233,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img234,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img235,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img236,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img240,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img241,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img245,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img247,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img249,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img250,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img251,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img252,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img254,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img255,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img257,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img258,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img260,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img262,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img264,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img266,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img267,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img269,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img270,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img274,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img275,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img276,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img277,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img279,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img280,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img281,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img282,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img284,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img285,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img286,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img289,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img291,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img292,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img298,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img300,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img301,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img302,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img303,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img304,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img305,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img306,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img307,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img311,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img312,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img315,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img320,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img321,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img322,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img325,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img326,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img327,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img328,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img331,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img332,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img333,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img334,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img336,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img337,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img340,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img345,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img348,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img352,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img353,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img355,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img358,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img359,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img360,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img361,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img363,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img364,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img365,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img366,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img367,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img372,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img373,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img378,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img380,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img382,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img383,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img384,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img385,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img386,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img387,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img388,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img391,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img395,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img396,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img416,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img418,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img419,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img420,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img421,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img422,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img423,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img424,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img425,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img432,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img435,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img438,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img441,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img442,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img443,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img444,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img445,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img446,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img448,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img449,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img450,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img451,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img452,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img453,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img454,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img455,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img456,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img457,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img458,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img460,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img461,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img462,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img463,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img464,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img467,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img468,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img470,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img476,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img480,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img481,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img485,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img488,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img489,
        },
       
    ]

    const [model, setModel] = useState(false);
const [tempImgSrc, setTempImgSrc] = useState('')
const [loading, setLoading] = useState(false)

const shuffle = a => {
    for (let i = a.length; i; i--) {
        let j = Math.floor(Math.random() * i);
        [a[i - 1], a[j]] = [a[j], a[i - 1]];
    }
  }
  
  shuffle(imageData);
  
useEffect(() => {
  setLoading(true)
  setTimeout(() => {
    setLoading(false)
  },5000)

},[])

const getImg = (imgSrc) => {
    setTempImgSrc(imgSrc);
    setModel(true);
}
    return (
        <>
        {
          loading ? <div className='cliper-middle'><ClipLoader color={'#D0021B'} loading={loading} size={100} /></div>
          :
          <div className='all__main__container'>
                <div className={model? "model open":"model"}>
                    <img src={tempImgSrc} alt="" />
                    <CloseIcon onClick={() => setModel(false)}/>
                </div>
                <div className="gallery">
                    {
                        imageData.map((item, index) => {
                            return(
                                <div className='pics' key={index} onClick={() => getImg(item.imgSrc)}>
                                <img src={item.imgSrc} style={{width:'100%'}} alt="" />
                                {/* <div className='image__overlay'>
                                    <h4>{item.title}</h4>
                                    <p>{item.description}</p>
                                </div> */}
                            </div>
                            )
                        })
                    }
                </div>
            </div>
        }
          
        </>
    );
};

export default Exterior;