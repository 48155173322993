import React, { useEffect, useState } from 'react';
import GALLERY from './All';
import ClipLoader from "react-spinners/ClipLoader";

const AllMain = () => {
    const [loading, setLoading] = useState(false)
    useEffect(() => {
        setLoading(true)
        setTimeout(() => {
          setLoading(false)
        },5000)
      
      },[])
    return (
        <>
            {
                loading ? <div className='cliper-middle'><ClipLoader color={'#D0021B'} loading={loading} size={100} /></div>:
                <GALLERY></GALLERY>
            }
        </>
    );
};

export default AllMain;