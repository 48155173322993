import React from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import studioVideo from "../../../img/Final.mp4";
import { TabTitle } from "../../GeneralFunction";
import "./Studio.css";

const Studio = () => {
  TabTitle("Studio - Arch Pixel");
  return (
    <div className="studio__container">
      <div className="row">
        <div className="col-md-5 studio__body">
          <h1 className="studio__title">EACH PIXEL MATTERS | STUDIO</h1>
          <video src={studioVideo} controls></video>
          <p>
            Located in Two Star Tower, 178 East Tejturi Bazar, Farmgate, Dhaka
            1215. ARCH PIXEL is a creative studio with 9 years of
            experience in the industry. We integrate a passion for real estate,
            architecture and art to create dynamic visuals and short films.
            <br />
            <br />
            As a studio of artists, we are skilled in the use of 3D,
            photography, design and filmmaking. We maintain the highest level of
            quality and a keen responsiveness to the changing demands of our
            clients. <br />
            <br />
            Our workplace fosters collaboration and creativity. This freedom
            enables us to lead in a fast-paced, competitive industry. <br />
            <br />
            From 3D illustrations to animations and virtual reality, we produce
            stunning imagery to support the needs of a diverse client base. Our
            3D illustrations and films have been utilized by developers, brokers
            and Fortune 100 companies around the globe.
          </p>
          <div className="studio__services">
            <p>
              <Link to={"/service"}>SERVICES</Link>
            </p>
            <p>Discover more about our process and the services ARCH PIXEL
              can offer.</p>
          </div>
          <div className="studio__clients">
            <p>
              <Link to={"/clients"}>CLIENTS</Link>
            </p>
            <p>Browse our most recent client roster.</p>
          </div>
          <p>
            Interested in working for a leading creative studio?{" "}
            <Link to={"/careers"}>Click here</Link> to view our current job
            openings.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Studio;
