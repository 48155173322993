import React, { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import './All.css';
import { TabTitle } from '../../GeneralFunction';

import img1 from '../../../img/Interior Render pic/Aloffr20 3RD.jpg'
import img2 from '../../../img/Interior Render pic/Amit8171 Community space.jpg'
import img3 from '../../../img/Interior Render pic/Amit8171 GYM_1.jpg'
import img4 from '../../../img/Interior Render pic/Amit8171 GYM_2.jpg'
import img5 from '../../../img/Interior Render pic/Amit8171 kit+din.jpg'
import img6 from '../../../img/Interior Render pic/Amit8171 Pool table.jpg'
import img8 from '../../../img/Interior Render pic/Amit8171 Table Tenis.jpg'
import img9 from '../../../img/Interior Render pic/Amit8171 Theater_1.jpg'
import img10 from '../../../img/Interior Render pic/Amit8171 Theater_2.jpg'
import img13 from '../../../img/Interior Render pic/BeeHomes Bathroom.jpg'
import img18 from '../../../img/Interior Render pic/Bhangals 3.jpg'
import img19 from '../../../img/Interior Render pic/Bhangals 4.jpg'
import img20 from '../../../img/Interior Render pic/Blakedog 1st.jpg'
import img21 from '../../../img/Interior Render pic/Blakedog 2nd floor_1.jpg'
import img22 from '../../../img/Interior Render pic/Blakedog 2nd floor_2.jpg'
import img25 from '../../../img/Interior Render pic/Blakedog 2nd1.jpg'
import img26 from '../../../img/Interior Render pic/Blakedog 3rd.jpg'
import img27 from '../../../img/Interior Render pic/Blakedog 4th.jpg'
import img28 from '../../../img/Interior Render pic/Blakedog 5th.jpg'
import img29 from '../../../img/Interior Render pic/Blakedog INT_1.jpg'
import img30 from '../../../img/Interior Render pic/Blakedog INT_2.jpg'
import img36 from '../../../img/Interior Render pic/Ethanb.632         3rd floor_10.jpg'
import img37 from '../../../img/Interior Render pic/Ethanb.632         3rd floor_9.jpg'
import img38 from '../../../img/Interior Render pic/Ethanb.632        2nd floor_3.jpg'
import img39 from '../../../img/Interior Render pic/Golderkeygroup Bathroom_02.png'
import img44 from '../../../img/Interior Render pic/Jacalbarracin V_1b.jpg'
import img45 from '../../../img/Interior Render pic/Jacalbarracin V_1c.jpg'
import img48 from '../../../img/Interior Render pic/Jcalbarracin3_ 5th.jpg'
import img49 from '../../../img/Interior Render pic/Jcalbarracin3_ Interior_2.jpg'
import img50 from '../../../img/Interior Render pic/Jcalbarracin3_ Stair_1.jpg'
import img51 from '../../../img/Interior Render pic/Jcalbarracin3_1st.jpg'
import img53 from '../../../img/Interior Render pic/Joybooker View_1.jpg'
import img54 from '../../../img/Interior Render pic/Joybooker View_2.jpg'
import img56 from '../../../img/Interior Render pic/Joybooker View_4.jpg'
import img57 from '../../../img/Interior Render pic/Joybooker View_5.jpg'
import img59 from '../../../img/Interior Render pic/Kamal jay suraya Roof top 3.jpg'
import img60 from '../../../img/Interior Render pic/Kaliesha kitchen2nd.jpg'
import img61 from '../../../img/Interior Render pic/Kaliesha kitchen1st.jpg'
import img62 from '../../../img/Interior Render pic/Kitchen  View_2.jpg'
import img63 from '../../../img/Interior Render pic/Kitchen  View_3.jpg'
import img64 from '../../../img/Interior Render pic/Mccormickteam 01.jpg'
import img67 from '../../../img/Interior Render pic/Mccormickteam 04.jpg'
import img69 from '../../../img/Interior Render pic/Mccormickteam 06.jpg'
import img70 from '../../../img/Interior Render pic/Mccormickteam bath-2.jpg'
import img71 from '../../../img/Interior Render pic/Mccormickteam Bath_1.jpg'
import img72 from '../../../img/Interior Render pic/Mccormickteam Bath_3.jpg'
import img74 from '../../../img/Interior Render pic/Mccormickteam Bed_3.jpg'
import img75 from '../../../img/Interior Render pic/Mccormickteam Bed_3.jpg'
import img79 from '../../../img/Interior Render pic/Neadie GROUND FLOOR_BED 1_V2.jpg'
import img83 from '../../../img/Interior Render pic/Office_1.jpg'
import img84 from '../../../img/Interior Render pic/Office_2.jpg'
import img85 from '../../../img/Interior Render pic/Office_3.jpg'
import img86 from '../../../img/Interior Render pic/Office_4.jpg'
import img87 from '../../../img/Interior Render pic/Office_5.jpg'
import img88 from '../../../img/Interior Render pic/Pamma83 Toilet2_1st floor.jpg'
import img89 from '../../../img/Interior Render pic/Pamma83 Utility_ground floor.jpg'
import img97 from '../../../img/Interior Render pic/Rankgalea 1st.jpg'
import img98 from '../../../img/Interior Render pic/Rankgalea 2nd.jpg'
import img99 from '../../../img/Interior Render pic/Rankgalea 3rd.jpg'
import img100 from '../../../img/Interior Render pic/Rankgalea 4th.jpg'
import img101 from '../../../img/Interior Render pic/Rankgalea 5th.jpg'
import img102 from '../../../img/Interior Render pic/Rankgalea 6th.jpg'
import img103 from '../../../img/Interior Render pic/Rankgalea 7th.jpg'
import img104 from '../../../img/Interior Render pic/Rankgalea L shape .jpg'
import img109 from '../../../img/Interior Render pic/Resulhan 15151515.jpg'
import img110 from '../../../img/Interior Render pic/Resulhan 22.jpg'
import img116 from '../../../img/Interior Render pic/Resulhan 666.jpg'
import img117 from '../../../img/Interior Render pic/Resulhan t-1-ggg.jpg'
import img125 from '../../../img/Interior Render pic/Rui Yamaguchi 5th view222.jpg'
import img126 from '../../../img/Interior Render pic/Rui Yamaguchi 6th view2222.jpg'
import img130 from '../../../img/Interior Render pic/Sinthusen 2 Kit_2.jpg'
import img131 from '../../../img/Interior Render pic/Sinthusen 2 Living_1.jpg'
import img133 from '../../../img/Interior Render pic/Sinthusen 2 Toilet_1.jpg'
import img149 from '../../../img/Interior Render pic/Taypty Kitchen_m1.jpg'
import img150 from '../../../img/Interior Render pic/Taypty Living_1.jpg'
import img153 from '../../../img/Interior Render pic/Taypty Master Bed_1.jpg'
import img158 from '../../../img/Aredhouse/BED ROOM_1.jpg'
import img159 from '../../../img/Aredhouse/Bedroom_2.jpg'
import img160 from '../../../img/Aredhouse/Living_2.jpg'
import img161 from '../../../img/Aredhouse/New folder/Dining_2.jpg'
import img162 from '../../../img/Aredhouse/New folder/Dining_3.jpg'
import img163 from '../../../img/Aredhouse/New folder/Kitchen_1.jpg'
import img164 from '../../../img/Aredhouse/New folder/Living_2Final.jpg'
import img165 from '../../../img/Aredhouse/New folder/Toilet_1.jpg'
import img166 from '../../../img/Aredhouse/New folder/Toilet_2.jpg'
import img167 from '../../../img/Creative Home design/Dining.jpg'
import img172 from '../../../img/isiimmobilien/BEDROOM_54.jpg'
import img173 from '../../../img/isiimmobilien/Kitchen.jpg'
import img174 from '../../../img/isiimmobilien/Unit2_Kitchen.jpg'
import img175 from '../../../img/isiimmobilien/Unit4_BED1.jpg'
import img176 from '../../../img/Jay Verma/@ Living & Dining.jpg'
import img177 from '../../../img/Jay Verma/Bed_1.jpg'
import img178 from '../../../img/Jay Verma/Living_1.jpg'
import img179 from '../../../img/Jay Verma/Living_3.jpg'
import img183 from '../../../img/John Figuerado Jr  JWF Design/Dining_A.jpg'
import img184 from '../../../img/John Figuerado Jr  JWF Design/Iso_toilet.jpg'
import img185 from '../../../img/John Figuerado Jr  JWF Design/Kitchen&Dining_A.jpg'
import img186 from '../../../img/John Figuerado Jr  JWF Design/Kitchen_A.jpg'
import img188 from '../../../img/Lewiskeating/Unit2_Kitchen.jpg'
import img190 from '../../../img/Lewiskeating/Unit2_Toilet.jpg'
import img192 from '../../../img/Matthew/1st Floor dining.jpg'
import img193 from '../../../img/Matthew/1st Floor kitchen.jpg'
import img194 from '../../../img/Matthew/1st Floor Master bed.jpg'
import img196 from '../../../img/Matthew/1st floor_Game1.jpg'
import img197 from '../../../img/Matthew/1st floor_Game2.jpg'
import img198 from '../../../img/Matthew/1st floor_toilet2.jpg'
import img199 from '../../../img/Matthew/Basement Dining.jpg'
import img200 from '../../../img/Matthew/Basement Kitchen.jpg'
import img201 from '../../../img/Matthew/Basement Living.jpg'
import img202 from '../../../img/Matthew/Basement Masterbed.jpg'
import img203 from '../../../img/Matthew/Basement Toilet.jpg'
import img204 from '../../../img/Matthew/Basement Utility.jpg'
import img205 from '../../../img/Matthew/Basement_Master Bed1.jpg'
import img207 from '../../../img/Matthew/Ground_ Toilet1.jpg'
import img208 from '../../../img/Matthew/Ground_Bed1.jpg'
import img209 from '../../../img/Matthew/Ground_Kitchen.jpg'
import img210 from '../../../img/Matthew/Ground_Kitchen2.jpg'
import img211 from '../../../img/Matthew/Ground_Master Bed1.jpg'
import img212 from '../../../img/Matthew/Ground_Utility.jpg'
import img215 from '../../../img/New folder/1st floor_ BedB.jpg'
import img216 from '../../../img/New folder/1st floor_loft2.jpg'
import img217 from '../../../img/New folder/1st floor_Master Bed.jpg'
import img218 from '../../../img/New folder/1st floor_Master Bed2.jpg'
import img219 from '../../../img/New folder/1st floor_MasterToilet1B.jpg'
import img220 from '../../../img/New folder/Ground Floor_Dining.jpg'
import img221 from '../../../img/New folder/Ground Floor_Living.jpg'
import img222 from '../../../img/New folder/Ground Floor_staircase.jpg'



// exterior

import img223 from '../../../img/Exterior Render pic/1st-image_49855682446_o.jpg'
import img224 from '../../../img/Exterior Render pic/1st_view_49620507773_o.jpg'
import img227 from '../../../img/Exterior Render pic/2nd-image_49855988187_o.jpg'
import img228 from '../../../img/Exterior Render pic/2nd-view_49914993236_o.jpg'
import img229 from '../../../img/Exterior Render pic/2nd_image_49621020821_o.jpg'
import img230 from '../../../img/Exterior Render pic/30624266_2065494227025613_649921339553621293_n_42912652155_o.jpg'
import img231 from '../../../img/Exterior Render pic/36512450_2118922578349444_6161316248958271488_n_28879855097_o.jpg'
import img233 from '../../../img/Exterior Render pic/3d-view_49621031361_o.jpg'
import img234 from '../../../img/Exterior Render pic/3d-visualization_49621233137_o.jpg'
import img235 from '../../../img/Exterior Render pic/3rd-view_49705447662_o.jpg'
import img236 from '../../../img/Exterior Render pic/4444444444_43933031860_o.jpg'
import img240 from '../../../img/Exterior Render pic/8th-view-1_49620488673_o.jpg'
import img241 from '../../../img/Exterior Render pic/Aloffr20 F_4.jpg'
import img245 from '../../../img/Exterior Render pic/Aymaneidi 4th.jpg'
import img247 from '../../../img/Exterior Render pic/Aymaneidi Final_1.jpg'
import img249 from '../../../img/Exterior Render pic/Barrabee 2nd version.jpg'
import img250 from '../../../img/Exterior Render pic/bashundhara3_30743150737_o.jpg'
import img251 from '../../../img/Exterior Render pic/borisal_44959013804_o.jpg'
import img252 from '../../../img/Exterior Render pic/BeeHomes  Exterior1.jpg'
import img254 from '../../../img/Exterior Render pic/Bijjujn L_2.jpg'
import img255 from '../../../img/Exterior Render pic/Blakedog EXT_1.jpg'
import img257 from '../../../img/Exterior Render pic/Bostonbomber18 Final Image2.jpg'
import img258 from '../../../img/Exterior Render pic/Broggy 1st.jpg'
import img260 from '../../../img/Exterior Render pic/Delpops 1.jpg'
import img262 from '../../../img/Exterior Render pic/Delpops 5.jpg'
import img264 from '../../../img/Exterior Render pic/Derekgaudet603 1st version.jpg'
import img266 from '../../../img/Exterior Render pic/Eniomaiale View_1.jpg'
import img267 from '../../../img/Exterior Render pic/EthanB 632 __8 house 6_front.jpg'
import img269 from '../../../img/Exterior Render pic/EthanB 632 __8 house 8_ front1.jpg'
import img270 from '../../../img/Exterior Render pic/Ethanb.632 2.jpg'
import img274 from '../../../img/Exterior Render pic/faisal_30743148137_o.jpg'
import img275 from '../../../img/Exterior Render pic/final-duplex-project_45633254282_o.jpg'
import img276 from '../../../img/Exterior Render pic/final-image_49705418612_o.jpg'
import img277 from '../../../img/Exterior Render pic/final-view_49621284712_o.jpg'
import img279 from '../../../img/Exterior Render pic/for-freelancer-copy_49620998446_o.jpg'
import img280 from '../../../img/Exterior Render pic/front-final-close_49705121546_o.jpg'
import img281 from '../../../img/Exterior Render pic/Fitnesselite 1st Image.jpg'
import img282 from '../../../img/Exterior Render pic/Fitnesselite 2nd view.jpg'
import img284 from '../../../img/Exterior Render pic/Fivesquid 3rd_Final.jpg'
import img285 from '../../../img/Exterior Render pic/Fivesquidevening.jpg'
import img286 from '../../../img/Exterior Render pic/Free work View_1.jpg'
import img289 from '../../../img/Exterior Render pic/glorious-garden_30743146997_o.jpg'
import img291 from '../../../img/Exterior Render pic/Gamesgid v2.jpg'
import img292 from '../../../img/Exterior Render pic/Gamesgid v3.jpg'
import img298 from '../../../img/Exterior Render pic/Guv B First Image.jpg'
import img300 from '../../../img/Exterior Render pic/Haroon Iqbal 3rd version.jpg'
import img302 from '../../../img/Exterior Render pic/Herrestates 2nd.jpg'
import img303 from '../../../img/Exterior Render pic/Herrestates 3.jpg'
import img304 from '../../../img/Exterior Render pic/Himalchuhan F_1.jpg'
import img305 from '../../../img/Exterior Render pic/i-will-create-3d-rendering-architecture-project-for-real-estate_49621254502_o.jpg'
import img306 from '../../../img/Exterior Render pic/image.png'
import img307 from '../../../img/Exterior Render pic/Interior 1st.jpg'
import img311 from '../../../img/Exterior Render pic/Jacalbarracin 1st8.jpg'
import img312 from '../../../img/Exterior Render pic/Jacalbarracin 1stt.jpg'
import img315 from '../../../img/Exterior Render pic/Jacalbarracin 2ndt.jpg'
import img320 from '../../../img/Exterior Render pic/Jacalbarracin Front view...jpg'
import img321 from '../../../img/Exterior Render pic/Jacalbarracin Front_day.jpg'
import img322 from '../../../img/Exterior Render pic/Jacalbarracin View_3.jpg'
import img325 from '../../../img/Exterior Render pic/James_smith001 1sts.jpg'
import img326 from '../../../img/Exterior Render pic/Jamesdeanuk 1st view.jpg'
import img327 from '../../../img/Exterior Render pic/Jamesdeanuk 2nd view .jpg'
import img328 from '../../../img/Exterior Render pic/Jamesdeanuk 3rd View .jpg'
import img331 from '../../../img/Exterior Render pic/Jayvarmal123     1_4 - Photo.jpg'
import img332 from '../../../img/Exterior Render pic/Jayvarmal123    1_1 - Photo.jpg'
import img333 from '../../../img/Exterior Render pic/Jayvarmal123 1.jpg'
import img334 from '../../../img/Exterior Render pic/Jayvarmal123 1st.jpg'
import img336 from '../../../img/Exterior Render pic/Jayvarmal123 back day.jpg'
import img337 from '../../../img/Exterior Render pic/Jayvarmal123 back night.jpg'
import img340 from '../../../img/Exterior Render pic/Jcalbarracin3_ Exterior1.jpg'
import img345 from '../../../img/Exterior Render pic/Jonescopl 2ND.jpg'
import img348 from '../../../img/Exterior Render pic/Jonjon 2005   1st vIew.jpg'
import img352 from '../../../img/Exterior Render pic/Joybooker Front2.jpg'
import img353 from '../../../img/Exterior Render pic/kangaroo UK 3rd1 (2).jpg'
import img355 from '../../../img/Exterior Render pic/Ladyschourup 1.jpg'
import img358 from '../../../img/Exterior Render pic/Lambo exterior D.jpg'
import img359 from '../../../img/Exterior Render pic/musfika-maliha-final11111_31811366358_o.jpg'
import img360 from '../../../img/Exterior Render pic/Marketflorida 2nd.jpg'
import img361 from '../../../img/Exterior Render pic/Matthewbaldassa Back_1.jpg'
import img363 from '../../../img/Exterior Render pic/MatthewbaldassaFront_1.jpg'
import img364 from '../../../img/Exterior Render pic/Mikicar1978 Night.jpg'
import img365 from '../../../img/Exterior Render pic/Mikicar1978_2nd Terrace.jpg'
import img366 from '../../../img/Exterior Render pic/Millenia95 2nd submit.jpg'
import img367 from '../../../img/Exterior Render pic/Mohassan162 1st.jpg'
import img372 from '../../../img/Exterior Render pic/nilphamari1111-copy_31811376628_o.jpg'
import img373 from '../../../img/Exterior Render pic/north-east-view_49621309287_o.jpg'
import img378 from '../../../img/Exterior Render pic/Pamma83 Front_3.jpg'
import img380 from '../../../img/Exterior Render pic/Pharmtect View_1.jpg'
import img382 from '../../../img/Exterior Render pic/Phil T 2nd.....jpg'
import img383 from '../../../img/Exterior Render pic/Phil T 4th.jpg'
import img384 from '../../../img/Exterior Render pic/PPH project 1st views.jpg'
import img385 from '../../../img/Exterior Render pic/regencyu-market_31811373198_o.jpg'
import img386 from '../../../img/Exterior Render pic/restaurent-ext_30743135897_o.jpg'
import img387 from '../../../img/Exterior Render pic/Rchaparro2000 Final Design.jpg'
import img388 from '../../../img/Exterior Render pic/Rchaparro2000 First view.jpg'
import img391 from '../../../img/Exterior Render pic/sanaullah2_31811369578_o.jpg'
import img395 from '../../../img/Exterior Render pic/st-angle-view_49915292342_o.jpg'
import img396 from '../../../img/Exterior Render pic/Sarahgibson 3rd.jpg'
import img416 from '../../../img/Exterior Render pic/Sinthusen 4th.jpg'
import img418 from '../../../img/Exterior Render pic/Sinthusen Final_3.jpg'
import img419 from '../../../img/Exterior Render pic/Sree 1st.jpg'
import img420 from '../../../img/Exterior Render pic/Stephenstewa248 Final version.jpg'
import img421 from '../../../img/Exterior Render pic/Stephenstewa248 Final_View_1.jpg'
import img422 from '../../../img/Exterior Render pic/Stephenstewa248 Final_View_2.jpg'
import img423 from '../../../img/Exterior Render pic/Studio View_3.jpg'
import img424 from '../../../img/Exterior Render pic/taher-kaka2_49620496518_o.jpg'
import img425 from '../../../img/Exterior Render pic/Tanjila 1st.jpg'
import img432 from '../../../img/Exterior Render pic/Westfisher v2.jpg'
import img435 from '../../../img/375 dorchester road, weymouth/Plot 1_2.jpg'
import img438 from '../../../img/375 dorchester road, weymouth/View_1.jpg'
import img441 from '../../../img/Anthony Papadopoulos/Front_1.jpg'
import img442 from '../../../img/Anthony Papadopoulos/Front_2.jpg'
import img443 from '../../../img/Anthony Papadopoulos/Front_3.jpg'
import img444 from '../../../img/APPLEGREEN HOMES/GOWERS STREET_1.jpg'
import img445 from '../../../img/APPLEGREEN HOMES/GOWERS STREET_3.jpg'
import img446 from '../../../img/BHAGWANT SINGH SANDHU/12621,89A AVENUE,Night.jpg'
import img448 from '../../../img/Creative Home design/Back.jpg'
import img449 from '../../../img/Creative Home design/Front View.jpg'
import img450 from '../../../img/isiimmobilien/Front.png'
import img451 from '../../../img/Jay Verma/V2.jpg'
import img452 from '../../../img/Jay Verma/View_1.jpg'
import img453 from '../../../img/JC Albarracin     A1 Design and Construction Ltd. Co/23814 Andora Ridge.jpg'
import img454 from '../../../img/JC Albarracin     A1 Design and Construction Ltd. Co/23814 Andora Ridgev2.jpg'
import img455 from '../../../img/JC Albarracin     A1 Design and Construction Ltd. Co/24318 CANYON ROW.jpg'
import img456 from '../../../img/JC Albarracin     A1 Design and Construction Ltd. Co/Empress Crossing dr.jpg'
import img457 from '../../../img/Lee Vincent High Park Homes/Guilsford.jpg'
import img458 from '../../../img/Lee Vincent High Park Homes/Surbiton.jpg'
import img460 from '../../../img/Lee Vincent High Park Homes/Surbiton_3.jpg'
import img461 from '../../../img/leightonjames1/The Avenue Yatton.jpg'
import img462 from '../../../img/leightonjames1/The Croft_1.jpg'
import img463 from '../../../img/leightonjames1/The Croft_2.jpg'
import img464 from '../../../img/2 Lumley road.jpg'
import img467 from '../../../img/2 Lumley road_4.jpg'
import img468 from '../../../img/154 Eastcote Roadv2.jpg'
import img470 from '../../../img/1009 N. Ind.jpg'
import img476 from '../../../img/AldersgateRoadTop.jpg'
import img480 from '../../../img/Dinobia.jpg'
import img481 from '../../../img/Ext_1.jpg'
import img485 from '../../../img/Front_Exterior.jpg'
import img488 from '../../../img/RICK_1.jpg'
import img489 from '../../../img/RICK_2.jpg'

// floorplan
import img490 from '../../../img/3d Floor Plan/2.Obergeschoß3D floor plan.jpg'
import img491 from '../../../img/3d Floor Plan/2nd Evelyn __ 2019 submission.jpg'
import img500 from '../../../img/3d Floor Plan/Dayoajisafe 2nd floor.jpg'
import img502 from '../../../img/3d Floor Plan/Dayoajisafe Ground Floor Final.jpg'
import img514 from '../../../img/3d Floor Plan/Esenam24 ground.jpg'
import img516 from '../../../img/3d Floor Plan/Friend444 2nd_floor.jpg'
import img517 from '../../../img/3d Floor Plan/Friend444 3rd floor.jpg'
import img518 from '../../../img/3d Floor Plan/Ground Floor_1.jpg'
import img519 from '../../../img/3d Floor Plan/Ground Floor_2.jpg'
import img522 from '../../../img/3d Floor Plan/James_smith001 1st_3d.jpg'
import img524 from '../../../img/3d Floor Plan/James_smith001 2nd_3d floor lan.jpg'
import img525 from '../../../img/3d Floor Plan/James_smith001 Final_1.jpg'
import img526 from '../../../img/3d Floor Plan/Jeffereydagbo  3rd floor.jpg'
import img527 from '../../../img/3d Floor Plan/Jeffereydagbo 1st floor.jpg'
import img528 from '../../../img/3d Floor Plan/Jeffereydagbo 2nd floor.jpg'
import img529 from '../../../img/3d Floor Plan/KIRAN REARDON 1st_1 (3d floor paln) .jpg'
import img530 from '../../../img/3d Floor Plan/KIRAN REARDON 1st_2(3d floor paln).jpg'
import img531 from '../../../img/3d Floor Plan/KIRAN REARDON 2nd_1(3d floor paln).jpg'
import img532 from '../../../img/3d Floor Plan/KIRAN REARDON 2nd_2(3d floor paln).jpg'
import img533 from '../../../img/3d Floor Plan/Lekansaromi 1st.jpg'
import img534 from '../../../img/3d Floor Plan/Lekansaromi 1st.jpg'
import img537 from '../../../img/3d Floor Plan/Lekansaromi 7th.jpg'
import img538 from '../../../img/3d Floor Plan/Luke h final image.jpg'
import img539 from '../../../img/3d Floor Plan/montego.jpg'
import img540 from '../../../img/3d Floor Plan/Mail floor 3d.jpg'
import img541 from '../../../img/3d Floor Plan/MC 1st Floor final.jpg'
import img542 from '../../../img/3d Floor Plan/MC 2nd floor.jpg'
import img543 from '../../../img/3d Floor Plan/MC final correction.jpg'
import img544 from '../../../img/3d Floor Plan/MC first floor.jpg'
import img545 from '../../../img/3d Floor Plan/MC First_Floor_Final.jpg'
import img546 from '../../../img/3d Floor Plan/MC ground floor plan.jpg'
import img547 from '../../../img/3d Floor Plan/MC ground floor.jpg'
import img548 from '../../../img/3d Floor Plan/Michele dunn bar 3d floor plan 1 .jpg'
import img553 from '../../../img/3d Floor Plan/Miragreenworld Floor_1.jpg'
import img566 from '../../../img/3d Floor Plan/Project P 10mx5m floor 3d.jpg'
import img567 from '../../../img/3d Floor Plan/Project P 1st floor_1.jpg'
import img568 from '../../../img/3d Floor Plan/Project P 1st Floor_2.jpg'
import img569 from '../../../img/3d Floor Plan/Project P 8mx8m  floor.jpg'
import img570 from '../../../img/3d Floor Plan/Raised ranch_Basement_3d floor plan.jpg'
import img571 from '../../../img/3d Floor Plan/Reni D  3d floor plan 2.jpg'
import img572 from '../../../img/3d Floor Plan/Rowdhaalshamsi Final render.jpg'
import img573 from '../../../img/3d Floor Plan/Rui Yamaguchi 7th view.jpg'
import img574 from '../../../img/3d Floor Plan/Saraazali barbados 1.jpg'
import img575 from '../../../img/3d Floor Plan/Saraazali Cayman.jpg'
import img576 from '../../../img/3d Floor Plan/Saraazali Fiji.jpg'
import img577 from '../../../img/3d Floor Plan/Saraazali Montego.jpg'
import img578 from '../../../img/3d Floor Plan/Shaneturner199 Follets 3d floor plan .jpg'
import img579 from '../../../img/3d Floor Plan/Shaneturner199 Kauai 3d  ground floor plan.jpg'
import img580 from '../../../img/3d Floor Plan/Shaneturner199 Kauai 3d 1st floor plan .jpg'
import img582 from '../../../img/3d Floor Plan/Tahiti.jpg'
import img585 from '../../../img/3d Floor Plan/Tanjila 3d floor plan2.jpg'
import img589 from '../../../img/3d Floor Plan/Tanjila s (1).jpg'
import img590 from '../../../img/3d Floor Plan/Tanjila s (2).jpg'


const GALLERY = () => {
    TabTitle('All Images - Arch Pixel');

    let imageData = [

        // interiro
        
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img1,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img2,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img3,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img4,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img5,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img6,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img6,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img8,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img9,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img10,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img13,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img18,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img19,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img20,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img21,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img22,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img25,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img26,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img27,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img28,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img29,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img30,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img36,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img37,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img38,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img39,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img44,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img45,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img48,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img49,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img50,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img51,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img53,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img54,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img56,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img57,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img59,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img60,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img61,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img62,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img63,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img64,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img67,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img69,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img70,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img71,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img72,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img74,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img75,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img79,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img83,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img84,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img85,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img86,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img87,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img88,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img89,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img97,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img98,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img99,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img100,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img101,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img102,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img103,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img104,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img109,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img110,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img116,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img117,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img125,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img126,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img130,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img131,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img133,
        },
        
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img149,
        },
        
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img150,
        },
        
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img153,
        },
        
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img158,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img159,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img160,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img161,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img162,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img163,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img164,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img165,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img166,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img167,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img172,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img173,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img174,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img175,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img176,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img177,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img178,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img179,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img183,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img184,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img185,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img186,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img188,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img190,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img192,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img193,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img194,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img196,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img197,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img198,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img199,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img200,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img201,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img202,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img203,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img204,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img205,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img207,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img208,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img209,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img210,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img211,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img212,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img215,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img216,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img217,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img218,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img219,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img220,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img221,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img222,
        },

        // exterior//
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img223,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img224,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img227
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img228,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img229,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img230,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img231,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img233,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img234,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img235,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img236,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img240,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img241,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img245,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img247,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img249,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img250,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img251,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img252,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img254,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img255,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img257,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img258,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img260,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img262,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img264,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img266,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img267,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img269,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img270,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img274,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img275,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img276,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img277,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img279,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img280,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img281,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img282,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img284,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img285,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img286,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img289,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img291,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img292,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img298,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img300,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img302,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img303,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img304,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img305,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img306,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img307,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img311,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img312,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img315,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img320,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img321,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img322,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img325,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img326,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img327,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img328,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img331,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img332,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img333,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img334,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img336,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img337,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img340,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img345,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img348,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img352,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img353,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img355,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img358,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img359,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img360,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img361,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img363,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img364,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img365,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img366,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img367,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img372,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img373,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img378,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img380,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img382,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img383,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img384,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img385,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img386,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img387,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img388,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img391,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img395,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img396,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img416,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img418,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img419,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img420,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img421,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img422,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img423,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img424,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img425,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img432,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img435,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img438,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img441,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img442,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img443,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img444,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img445,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img446,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img448,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img449,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img450,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img451,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img452,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img453,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img454,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img455,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img456,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img457,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img458,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img460,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img461,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img462,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img463,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img464,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img467,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img468,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img470,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img476,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img480,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img481,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img485,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img488,
        },
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img489,
        },

        // floorplan
        {
            id:1,
            title:'',
            description:'',
            imgSrc:img490,
        },
          {
            id:1,
            title:'',
            description:'',
            imgSrc:img491,
        },
          {
            id:1,
            title:'',
            description:'',
            imgSrc:img500,
        },
          {
            id:1,
            title:'',
            description:'',
            imgSrc:img502,
        },
          {
            id:1,
            title:'',
            description:'',
            imgSrc:img514,
        },
          {
            id:1,
            title:'',
            description:'',
            imgSrc:img516,
        },
          {
            id:1,
            title:'',
            description:'',
            imgSrc:img517,
        },
          {
            id:1,
            title:'',
            description:'',
            imgSrc:img518,
        },
          {
            id:1,
            title:'',
            description:'',
            imgSrc:img519,
        },
          {
            id:1,
            title:'',
            description:'',
            imgSrc:img522,
        },
          {
            id:1,
            title:'',
            description:'',
            imgSrc:img524,
        },
          {
            id:1,
            title:'',
            description:'',
            imgSrc:img525,
        },
          {
            id:1,
            title:'',
            description:'',
            imgSrc:img526,
        },
          {
            id:1,
            title:'',
            description:'',
            imgSrc:img527,
        },
          {
            id:1,
            title:'',
            description:'',
            imgSrc:img528,
        },
          {
            id:1,
            title:'',
            description:'',
            imgSrc:img529,
        },
          {
            id:1,
            title:'',
            description:'',
            imgSrc:img530,
        },
          {
            id:1,
            title:'',
            description:'',
            imgSrc:img531,
        },
          {
            id:1,
            title:'',
            description:'',
            imgSrc:img532,
        },
          {
            id:1,
            title:'',
            description:'',
            imgSrc:img533,
        },
          {
            id:1,
            title:'',
            description:'',
            imgSrc:img534,
        },
          {
            id:1,
            title:'',
            description:'',
            imgSrc:img537,
        },
          {
            id:1,
            title:'',
            description:'',
            imgSrc:img538,
        },
          {
            id:1,
            title:'',
            description:'',
            imgSrc:img539,
        },
          {
            id:1,
            title:'',
            description:'',
            imgSrc:img540,
        },
          {
            id:1,
            title:'',
            description:'',
            imgSrc:img541,
        },
          {
            id:1,
            title:'',
            description:'',
            imgSrc:img542,
        },
          {
            id:1,
            title:'',
            description:'',
            imgSrc:img543,
        },
          {
            id:1,
            title:'',
            description:'',
            imgSrc:img544,
        },
          {
            id:1,
            title:'',
            description:'',
            imgSrc:img545,
        },
          {
            id:1,
            title:'',
            description:'',
            imgSrc:img546,
        },
          {
            id:1,
            title:'',
            description:'',
            imgSrc:img547,
        },
          {
            id:1,
            title:'',
            description:'',
            imgSrc:img548,
        },
          {
            id:1,
            title:'',
            description:'',
            imgSrc:img553,
        },
          {
            id:1,
            title:'',
            description:'',
            imgSrc:img566,
        },
          {
            id:1,
            title:'',
            description:'',
            imgSrc:img567,
        },
          {
            id:1,
            title:'',
            description:'',
            imgSrc:img568,
        },
          {
            id:1,
            title:'',
            description:'',
            imgSrc:img569,
        },
          {
            id:1,
            title:'',
            description:'',
            imgSrc:img570,
        },
          {
            id:1,
            title:'',
            description:'',
            imgSrc:img571,
        },
          {
            id:1,
            title:'',
            description:'',
            imgSrc:img572,
        },
          {
            id:1,
            title:'',
            description:'',
            imgSrc:img573,
        },
          {
            id:1,
            title:'',
            description:'',
            imgSrc:img574,
        },
          {
            id:1,
            title:'',
            description:'',
            imgSrc:img575,
        },
          {
            id:1,
            title:'',
            description:'',
            imgSrc:img576,
        },
          {
            id:1,
            title:'',
            description:'',
            imgSrc:img577,
        },
          {
            id:1,
            title:'',
            description:'',
            imgSrc:img578,
        },
          {
            id:1,
            title:'',
            description:'',
            imgSrc:img579,
        },
          {
            id:1,
            title:'',
            description:'',
            imgSrc:img580,
        },
          {
            id:1,
            title:'',
            description:'',
            imgSrc:img582,
        },
          {
            id:1,
            title:'',
            description:'',
            imgSrc:img585,
        },
          {
            id:1,
            title:'',
            description:'',
            imgSrc:img589,
        },
          {
            id:1,
            title:'',
            description:'',
            imgSrc:img590,
        },

    ]

    const [model, setModel] = useState(false);
    const [tempImgSrc, setTempImgSrc] = useState('')
    const shuffle = a => {
        for (let i = a.length; i; i--) {
            let j = Math.floor(Math.random() * i);
            [a[i - 1], a[j]] = [a[j], a[i - 1]];
        }
      }
      
      shuffle(imageData);

    const getImg = (imgSrc) => {
        setTempImgSrc(imgSrc);
        setModel(true);
    }
    

    return (
        <div className='all__main__container'>
            <div className={model? "model open":"model"}>
                <img src={tempImgSrc} alt="" />
                <CloseIcon onClick={() => setModel(false)}/>
            </div>
            <div className="gallery">
                {
                    imageData.map((item, index) => {
                        return(
                            <div className='pics' key={index} onClick={() => getImg(item.imgSrc)}>
                            <img src={item.imgSrc} style={{width:'100%'}} alt="" />
                            {/* <div className='image__overlay'>
                                <h4>{item.title}</h4>
                                <p>{item.description}</p>
                            </div> */}
                        </div>
                        )
                    })
                }
            </div>
        </div>
    );
};

export default GALLERY;