import React from "react";
import { TabTitle } from "../../GeneralFunction";
import "./Career.css";

const Career = () => {
  TabTitle('Career - Arch Pixel');
  return (
    <div className="career-container">
      <div className="row career-body">
        <div className="col-md-4 career-left-part">
          <h1 className="career-title">A TILTED WORKPLACE | CAREERS</h1>
          <p>
            Our studio, located in Two Star Tower, 178 East Tejturi Bazar Farmgate, Dhaka
            1215, is the definition of work
            hard / play hard. Late night project sessions are balanced by
            foosball and beer Fridays, and our casual environment invites
            creativity and collaboration. We love what we do. We are designers
            and innovators. We dream and challenge and inspire one another to
            grow as artists. ARCH PIXEL is a premiere 3D agency, and at our core
            is an exceptional team. We are always looking for new talent and
            welcome applications for the following positions:
          </p>
          <h2>3D ARTIST</h2>
          <ul>
            <li>Strong proficiency in 3ds Max, V-Ray and Photoshop</li>
            <li>A clear sense of image composition and design</li>
            <li>Skill in modeling, lighting and texturing</li>
            <li>
              Proficiency in the creation of both interior and exterior
              photorealistic renderings
            </li>
            <li>Experience in 3D animation</li>
            <li>Architectural background strongly preferred</li>
            <li>
              The ability to meet tight deadlines and flexibility in managing
              the demands of a fast-paced work environment
            </li>
            <li>Comfort in working both individually and on a team</li>
            <li>Eligibility to work in the United States</li>
          </ul>
          <p>
            To apply, please send your resume and your strongest portfolio
            pieces to{" "}
            <a href="mailto:mehedihasan019898@gmail.com">
              mehedihasan019898@gmail.com
            </a>
            . If considered, you will be asked to submit a test project in
            addition to the interview process.
          </p>
        </div>
      </div>
      <div className="col-md-8"></div>
    </div>
  );
};

export default Career;
