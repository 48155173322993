import React, { Suspense } from "react";
import { TabTitle } from "../GeneralFunction";
import "./Home.css";
import ClipLoader from "react-spinners/ClipLoader";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "../ErrorBoundry";
const HomeSlider = React.lazy(() => import("./HomeSlider"))
const HomeFooter = React.lazy(() => import("./HomeFooter"))

const Home = () => {
  TabTitle('3D Renderings & Architectural Animations - ARCH PIXEL | DHAKA, BANGLADESH');
  return (
    <div className="home__container">
      <ErrorBoundary FallbackComponent={ErrorFallback} onReset={() => {}}>
        <Suspense fallback={<div className='cliper-middle'><ClipLoader color={'#D0021B'} size={100} /></div>}>
          <HomeSlider/>
          <HomeFooter/>
        </Suspense>
      </ErrorBoundary>
    </div>
  );
};

export default Home;
