import React from 'react';
import { TabTitle } from '../GeneralFunction';

const NoMatch = () => {
    TabTitle('nothing-here - Arch Pixel');
    return (
        <div>
            <h1>sorry we don't find anything</h1>
        </div>
    );
};

export default NoMatch;