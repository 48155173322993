import React from "react";
import { TabTitle } from "../../GeneralFunction";
import "./Service.css";

const Service = () => {
  TabTitle("Service - Arch Pixel");
  return (
    <div className="service-container">
      <h1>OUR SERVICE</h1>
      <div className="service__container__top">
        <div className="element">
          <div className="service service__element__top1">
            <h1>EXTERIOR RENDERING</h1>
          </div>
        </div>
        <div className="element">
          <div className="service service__element__top2">
            <h1>INTERIOR RENDERING</h1>
          </div>
        </div>
      </div>
      <div className="service__container__middle">
        <div className="element">
          <div className="service service__element__middle1">
            <h1>2D FLOOR PLAN</h1>
          </div>
        </div>
        <div className="element">
          <div className="service service__element__middle2">
            <h1>VIDEO ANIMATION</h1>
          </div>
        </div>
        <div className="element">
          <div className="service service__element__middle3">
            <h1>VIRTUAL REALITY</h1>
          </div>
        </div>
      </div>
      <div className="service__container__bottom">
        <div className="element">
          <div className="service service__element__bottom1">
            <h1>LANDSCAPE RENDERING</h1>
          </div>
        </div>
        <div className="element">
          <div className="service service__element__bottom2">
            <h1>3D FLOOR PLAN</h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Service;
