import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faPhoneAlt } from "@fortawesome/free-solid-svg-icons";
import "./Contact.css";
import { Link } from "react-router-dom";
import { TabTitle } from "../GeneralFunction";

const Contact = () => {
  TabTitle("Contact - Arch Pixel");
  return (
    <div className="contact__container">
      <div className="row contact__body">
        <div className="col-md-4 contact__left__part">
          <div className="contact__top__part">
            <p>Have a project for us?</p>
            <p>
              <Link to={"/careers"}>Interested</Link> in working at ARCH PIXEL?
            </p>
            <p>
              Want to know more <Link to={"/studio"}>about us</Link>?
            </p>
            <p>Want to just chat 3D?</p>
            <p>We'd love to hear from you!</p>
          </div>
          <div className="contact__middle__part">
            <p>BANGLADESH</p>
            <p> Two Star Tower, 178 East Tejturi Bazar</p>
            <p>Farmgate, Dhaka 1215</p>
          </div>
          <div className="contact__bottom__part">
            <p>
              <a href="tel:+8801517814014">
                <FontAwesomeIcon icon={faPhoneAlt} /> +8801517814014
              </a>
            </p>
            <p>
              <a href="mailto:mehedihasan019898@gmail.com">
                <FontAwesomeIcon icon={faEnvelope} />{" "}
                mehedihasan019898@gmail.com
              </a>
            </p>
          </div>
        </div>
        <div className="col-md-8"></div>
      </div>
    </div>
  );
};

export default Contact;
