import React, { useState } from 'react';
import { NavLink, Link } from 'react-router-dom';
import { FiAlignJustify, FiXCircle, FiChevronDown } from "react-icons/fi";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookF, faInstagram, faTwitter, faWhatsapp, faYoutube } from '@fortawesome/free-brands-svg-icons'

import './Navbar.css';

const NavbarMenu = () => {
    const [isMenu, setisMenu] = useState(false);
    const [isResponsiveclose, setResponsiveclose] = useState(false);
    const toggleClass = () => {
        setisMenu(isMenu === false ? true : false);
        setResponsiveclose(isResponsiveclose === false ? true : false);
    };
    let boxClass = ["main-menu menu-right menuq1"];
    if (isMenu) {
        boxClass.push('menuq2');
    } else {
        boxClass.push('');
    }
    const [isMenuSubMenu, setMenuSubMenu] = useState(false);
    const toggleSubmenu = () => {
        setMenuSubMenu(isMenuSubMenu === false ? true : false);
    };
    const [isMenuSubMenu2, setMenuSubMenu2] = useState(false);
    const toggleSubmenu2 = () => {
        setMenuSubMenu2(isMenuSubMenu2 === false ? true : false);
    };

    let boxClassSubMenu = ["sub__menus"];
    if (isMenuSubMenu) {
        boxClassSubMenu.push('sub__menus__Active');
    } else {
        boxClassSubMenu.push('');
    }
    let boxClassSubMenu2 = ["sub__menus"];
    if (isMenuSubMenu2) {
        boxClassSubMenu2.push('sub__menus__Active');
    } else {
        boxClassSubMenu2.push('');
    }

    return (
        <header className="header header-height header__middle">
            <div className="navbar__container">
                <div className="row">
                    {/* Add Logo  */}
                    <div className="header__middle__logo">
                        <NavLink exact id='logo-link-style' to={`/`}>
                            <div className="logo-flex">
                                <div className='brand-name'><p><span>A</span>RCH <span>P</span>IXEL</p></div>
                            </div>
                        </NavLink>
                    </div>
                    <div className="header__middle__menus">
                        <nav className="main-nav" >
                            {/* Responsive Menu Button */}
                            {isResponsiveclose === true ? <>
                                <span className="menubar__button" style={{ display: 'none' }} onClick={toggleClass} > <FiXCircle />   </span>
                            </> : <>
                                <span className="menubar__button" style={{ display: 'none' }} onClick={toggleClass} > <FiAlignJustify />   </span>
                            </>}
                            <ul className={boxClass.join(' ')}>
                                <li onClick={toggleSubmenu} className="menu-item sub__menus__arrows" > <Link to={`#`}> ABOUT <FiChevronDown /> </Link>
                                    <ul className={boxClassSubMenu.join(' ')} >
                                        <li> <NavLink onClick={toggleClass} activeClassName='is-active' to="/studio"> STUDIO </NavLink> </li>
                                        <li><NavLink onClick={toggleClass} activeClassName='is-active' to={`/service`}> SERVICE </NavLink> </li>
                                        <li><NavLink onClick={toggleClass} activeClassName='is-active' to={`/clients`}> CLIENTS </NavLink> </li>
                                        <li><NavLink onClick={toggleClass} activeClassName='is-active' to={`/careers`}> CAREERS </NavLink> </li>
                                    </ul>
                                </li>
                                <li onClick={toggleSubmenu2} className="menu-item sub__menus__arrows" > <Link to={`#`}> GALLERY <FiChevronDown /> </Link>
                                    <ul className={boxClassSubMenu2.join(' ')} >
                                        <li> <NavLink onClick={toggleClass} activeClassName='is-active' to={`/all-images`}> ALL </NavLink> </li>
                                        <li><NavLink onClick={toggleClass} activeClassName='is-active' to={`/3D-Interior`}>3D INTERIOR</NavLink> </li>
                                        <li><NavLink onClick={toggleClass} activeClassName='is-active' to={`/3D-Exterior`}>3D EXTERIOR</NavLink> </li>
                                        <li><NavLink onClick={toggleClass} activeClassName='is-active' to={`/3D-Floor-Plan`}>3D FLOOR PLAN</NavLink> </li>
                                        <li><NavLink onClick={toggleClass} activeClassName='is-active' to={`/video`}>VIDEO</NavLink> </li>
                                        {/* <li><NavLink onClick={toggleClass} activeClassName='is-active' to={`/virtual-Reality`}> VIRTUAL REALITY </NavLink> </li>
                                        <li><NavLink onClick={toggleClass} activeClassName='is-active' to={`/virtual-Reality`}> LANDSCAPE </NavLink> </li> */}
                                    </ul>
                                </li>
                                <li className="menu-item" ><NavLink onClick={toggleClass} activeClassName='is-active' to={`/learning`}> LEARN </NavLink> </li>
                                <li className="menu-item" ><NavLink onClick={toggleClass} activeClassName='is-active' to={`/contact`}> CONTACT </NavLink> </li>
                                <div className="social__media d-flex">
                                    <div><span className='wall'> | </span></div>
                                    <ul className='d-flex social__media__icon'>
                                        <a href="" target="_blank" rel="noreferrer"><li><FontAwesomeIcon className="facebook__icon" icon={faFacebookF} /></li></a>
                                        <a href="" target="_blank" rel="noreferrer"><li><FontAwesomeIcon icon={faInstagram} /></li></a>
                                        <a href="https://twitter.com/LtdEmphatic" target="_blank" rel="noreferrer"><li><FontAwesomeIcon icon={faTwitter} /></li></a>
                                        <a href="https://www.youtube.com/channel/UCAJgQnff6TVQCp3-EzSomXQ/videos" target="_blank" rel="noreferrer"><li><FontAwesomeIcon className='youtube__icon' icon={faYoutube} /></li></a>
                                    </ul>
                                    
                                </div>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
            <a className='whatsapp-link' href={`https://web.whatsapp.com/send?phone=+8801850600856&text=Hi`} rel="noreferrer" target='_blank'>
            <div className='whatsapp_container' style={{width:'290px', height:'60px', position:'fixed', right:'20px', bottom:'20px'}}>
                <FontAwesomeIcon className='whatsapp__icon' icon={faWhatsapp} /><p>How can we help you?</p>
            </div>
            </a>
        </header>
    );
};

export default NavbarMenu;