import React from 'react';
import { TabTitle } from '../../GeneralFunction';

const Clients = () => {
    TabTitle('Clients - Arch Pixel');
    return (
        <div>
            <h1>WORKING ON IT</h1>
        </div>
    );
};

export default Clients;