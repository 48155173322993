import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import Career from "./Components/About/Career/Career";
import Clients from "./Components/About/Clients/Clients";
import Service from "./Components/About/Service/Service";
import Studio from "./Components/About/Studio/Studio";
import Contact from "./Components/Contact/Contact";
import Exterior from "./Components/Gellary/3dExterior/3dExterior";
import FloorPlan from "./Components/Gellary/3dFloorPlan/FloorPlan";
import Interior from "./Components/Gellary/3dInterior/3dInterior";
import All from "./Components/Gellary/All/AllMain";
import Films from "./Components/Gellary/Films/Films";
// import VirtualReality from "./Components/Gellary/VirtualReality/VirtualReality";
import Home from "./Components/Home/Home";
import Learning from "./Components/Learning/Learning";
import NavbarMenu from "./Components/NavbarMenu/NavbarMenu";
import NoMatch from "./Components/NoMatch/NoMatch";

function App() {
  return (
    <>
      <Router>
        <NavbarMenu />
        <Switch>
          <Route exact path= "/" component={Home}/>
          <Route exact path= "/home" component={Home}/>
          <Route exact path= "/contact" component={Contact}/>
          <Route exact path= "/learning" component={Learning}/>
          <Route exact path= "/careers" component={Career}/>
          <Route exact path= "/studio" component={Studio}/>
          <Route exact path= "/clients" component={Clients}/>
          <Route exact path= "/service" component={Service}/>
          <Route exact path= "/all-images" component={All}/>
          <Route exact path= "/3D-Interior" component={Interior}/>
          <Route exact path= "/3D-Exterior" component={Exterior}/>
          <Route exact path= "/3D-Floor-Plan" component={FloorPlan}/>
          <Route exact path= "/video" component={Films}/>
          {/* <Route exact path= "/virtual-Reality" component={VirtualReality}/> */}
          <Route exact path= "*" component={NoMatch}/>
       </Switch>
      </Router>
    </>
  );
}

export default App;
