import React, { useEffect, useState } from "react";
import { TabTitle } from "../../GeneralFunction";
import ClipLoader from "react-spinners/ClipLoader";

import "./Films.css";

const Films = () => {
    TabTitle("Films - Arch Pixel");


    let filmsData = [
        {
            id:'1',
            filmUrl:'https://www.youtube.com/embed/-o_pLHGoYyw'
        },
        {
            id:'1',
            filmUrl:'https://www.youtube.com/embed/rw7ukkTU_00'
        },
        {
            id:'1',
            filmUrl:'https://www.youtube.com/embed/H3aR8yttjuI'
        },
        {
            id:'1',
            filmUrl:'https://www.youtube.com/embed/gDcFNdRwewk'
        },
        {
            id:'1',
            filmUrl:'https://www.youtube.com/embed/aPl8VxrNIhg'
        },
        {
            id:'1',
            filmUrl:'https://www.youtube.com/embed/-UA_UIQ8eVc'
        },
    ]

    const [loading, setLoading] = useState(false)
    useEffect(() => {
        setLoading(true)
        setTimeout(() => {
          setLoading(false)
        },3000)
      
      },[])
  
  return (
    
    <>
      <div className="films__gallery">
        {
          loading ? <div className='cliper-middle'><ClipLoader color={'#D0021B'} loading={loading} size={100} /></div>:
          filmsData.map((item, index) => {
            return(
                <div className='youtube__video' key={index}>
                <iframe className="col-md-4" src={item.filmUrl} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                
            </div>
            )
        })
        }
      </div>
    </>
  );
};

export default Films;
