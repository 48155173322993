import React, { useEffect, useState } from "react";
import { TabTitle } from "../GeneralFunction";
import "./Learning.css";
import ClipLoader from "react-spinners/ClipLoader";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import coreldraw from '../../img/Learning_image/coreldraw-logo-png-transparent.png'

const Learning = () => {
  TabTitle('Learning - Arch Pixel');
  const [loading, setLoading] = useState(false)

  let imageData =[
    {
      imgSrc:"https://www.amesolutions.co.uk/wp-content/uploads/2020/07/AutoCAD-Logo.png",
      to:'',
      title:'AutoCAD'
    },
    {
      imgSrc:"https://www.bluegfx.com/images/bluegfx/product-logos/chaos-vray-3ds-max-logo.jpg",
      to:'',
      title:'V-ray'
    },
    {
      imgSrc:"https://1000logos.net/wp-content/uploads/2020/08/3ds-Max-Logo.jpg",
      to:'',
      title:'3ds Max'
    },
    {
      imgSrc:"https://logovtor.com/wp-content/uploads/2020/08/lumion-france-logo-vector.png",
      to:'',
      title:'Lumion'
    },
    {
      imgSrc:"https://wallpaperaccess.com/full/3389575.jpg",
      to:'',
      title:'Adobe PhotoShop'
    },
    {
      imgSrc:"https://download.logo.wine/logo/Adobe_Illustrator/Adobe_Illustrator-Logo.wine.png",
      to:'/contact',
      title:'Adobe Illustrator'
    },
    {
      imgSrc:"https://www.amd.com/system/files/144767-autodesk-revit-logo-1260x709.png",
      to:'/',
      title:'Revit Architecture'
    },
    {
      imgSrc:coreldraw,
      to:'/',
      title:'CorelDraw'
    },
  ]
  useEffect(() => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    },200)
  
  },[])
  return (
    <>
        {
      loading ? <div className='cliper-middle'><ClipLoader color={'#D0021B'} loading={loading} size={100} /></div>
      :
      <div className='learn__main__container'>
            <div className="learn_gallery">
                {
                    imageData.map((item, index) => {
                        return(
                            <div className='pics' key={index}>
                            <img src={item.imgSrc} style={{width:'100%'}} alt="" />
                            <div className='image__overlay'>
                              <h4>{item.title}</h4>
                                <Link to={item.to}><span className="button-style">READ MORE......</span></Link>
                            </div>
                        </div>
                        )
                    })
                }
            </div>
        </div>
    }
    </>
  );
};

export default Learning;
