import React, { useEffect, useState } from "react";
import { TabTitle } from "../../GeneralFunction";
import CloseIcon from '@mui/icons-material/Close';
import "./FloorPlan.css";

import img490 from '../../../img/3d Floor Plan/2.Obergeschoß3D floor plan.jpg'
import img491 from '../../../img/3d Floor Plan/2nd Evelyn __ 2019 submission.jpg'
import img500 from '../../../img/3d Floor Plan/Dayoajisafe 2nd floor.jpg'
import img502 from '../../../img/3d Floor Plan/Dayoajisafe Ground Floor Final.jpg'
import img514 from '../../../img/3d Floor Plan/Esenam24 ground.jpg'
import img516 from '../../../img/3d Floor Plan/Friend444 2nd_floor.jpg'
import img517 from '../../../img/3d Floor Plan/Friend444 3rd floor.jpg'
import img518 from '../../../img/3d Floor Plan/Ground Floor_1.jpg'
import img519 from '../../../img/3d Floor Plan/Ground Floor_2.jpg'
import img522 from '../../../img/3d Floor Plan/James_smith001 1st_3d.jpg'
import img524 from '../../../img/3d Floor Plan/James_smith001 2nd_3d floor lan.jpg'
import img525 from '../../../img/3d Floor Plan/James_smith001 Final_1.jpg'
import img526 from '../../../img/3d Floor Plan/Jeffereydagbo  3rd floor.jpg'
import img527 from '../../../img/3d Floor Plan/Jeffereydagbo 1st floor.jpg'
import img528 from '../../../img/3d Floor Plan/Jeffereydagbo 2nd floor.jpg'
import img529 from '../../../img/3d Floor Plan/KIRAN REARDON 1st_1 (3d floor paln) .jpg'
import img530 from '../../../img/3d Floor Plan/KIRAN REARDON 1st_2(3d floor paln).jpg'
import img531 from '../../../img/3d Floor Plan/KIRAN REARDON 2nd_1(3d floor paln).jpg'
import img532 from '../../../img/3d Floor Plan/KIRAN REARDON 2nd_2(3d floor paln).jpg'
import img533 from '../../../img/3d Floor Plan/Lekansaromi 1st.jpg'
import img534 from '../../../img/3d Floor Plan/Lekansaromi 1st.jpg'
import img537 from '../../../img/3d Floor Plan/Lekansaromi 7th.jpg'
import img538 from '../../../img/3d Floor Plan/Luke h final image.jpg'
import img539 from '../../../img/3d Floor Plan/montego.jpg'
import img540 from '../../../img/3d Floor Plan/Mail floor 3d.jpg'
import img541 from '../../../img/3d Floor Plan/MC 1st Floor final.jpg'
import img542 from '../../../img/3d Floor Plan/MC 2nd floor.jpg'
import img543 from '../../../img/3d Floor Plan/MC final correction.jpg'
import img544 from '../../../img/3d Floor Plan/MC first floor.jpg'
import img545 from '../../../img/3d Floor Plan/MC First_Floor_Final.jpg'
import img546 from '../../../img/3d Floor Plan/MC ground floor plan.jpg'
import img547 from '../../../img/3d Floor Plan/MC ground floor.jpg'
import img548 from '../../../img/3d Floor Plan/Michele dunn bar 3d floor plan 1 .jpg'
import img553 from '../../../img/3d Floor Plan/Miragreenworld Floor_1.jpg'
import img566 from '../../../img/3d Floor Plan/Project P 10mx5m floor 3d.jpg'
import img567 from '../../../img/3d Floor Plan/Project P 1st floor_1.jpg'
import img568 from '../../../img/3d Floor Plan/Project P 1st Floor_2.jpg'
import img569 from '../../../img/3d Floor Plan/Project P 8mx8m  floor.jpg'
import img570 from '../../../img/3d Floor Plan/Raised ranch_Basement_3d floor plan.jpg'
import img571 from '../../../img/3d Floor Plan/Reni D  3d floor plan 2.jpg'
import img572 from '../../../img/3d Floor Plan/Rowdhaalshamsi Final render.jpg'
import img573 from '../../../img/3d Floor Plan/Rui Yamaguchi 7th view.jpg'
import img574 from '../../../img/3d Floor Plan/Saraazali barbados 1.jpg'
import img575 from '../../../img/3d Floor Plan/Saraazali Cayman.jpg'
import img576 from '../../../img/3d Floor Plan/Saraazali Fiji.jpg'
import img577 from '../../../img/3d Floor Plan/Saraazali Montego.jpg'
import img578 from '../../../img/3d Floor Plan/Shaneturner199 Follets 3d floor plan .jpg'
import img579 from '../../../img/3d Floor Plan/Shaneturner199 Kauai 3d  ground floor plan.jpg'
import img580 from '../../../img/3d Floor Plan/Shaneturner199 Kauai 3d 1st floor plan .jpg'
import img582 from '../../../img/3d Floor Plan/Tahiti.jpg'
import img585 from '../../../img/3d Floor Plan/Tanjila 3d floor plan2.jpg'
import img589 from '../../../img/3d Floor Plan/Tanjila s (1).jpg'
import img590 from '../../../img/3d Floor Plan/Tanjila s (2).jpg'

import ClipLoader from "react-spinners/ClipLoader";

const FloorPlan = () => {
  TabTitle('3D-Floor-Plan - Arch Pixel');

  let imageData = [
    {
      id:1,
      title:'',
      description:'',
      imgSrc:img490,
  },
    {
      id:1,
      title:'',
      description:'',
      imgSrc:img491,
  },
    {
      id:1,
      title:'',
      description:'',
      imgSrc:img500,
  },
    {
      id:1,
      title:'',
      description:'',
      imgSrc:img502,
  },
    {
      id:1,
      title:'',
      description:'',
      imgSrc:img514,
  },
    {
      id:1,
      title:'',
      description:'',
      imgSrc:img516,
  },
    {
      id:1,
      title:'',
      description:'',
      imgSrc:img517,
  },
    {
      id:1,
      title:'',
      description:'',
      imgSrc:img518,
  },
    {
      id:1,
      title:'',
      description:'',
      imgSrc:img519,
  },
    {
      id:1,
      title:'',
      description:'',
      imgSrc:img522,
  },
    {
      id:1,
      title:'',
      description:'',
      imgSrc:img524,
  },
    {
      id:1,
      title:'',
      description:'',
      imgSrc:img525,
  },
    {
      id:1,
      title:'',
      description:'',
      imgSrc:img526,
  },
    {
      id:1,
      title:'',
      description:'',
      imgSrc:img527,
  },
    {
      id:1,
      title:'',
      description:'',
      imgSrc:img528,
  },
    {
      id:1,
      title:'',
      description:'',
      imgSrc:img529,
  },
    {
      id:1,
      title:'',
      description:'',
      imgSrc:img530,
  },
    {
      id:1,
      title:'',
      description:'',
      imgSrc:img531,
  },
    {
      id:1,
      title:'',
      description:'',
      imgSrc:img532,
  },
    {
      id:1,
      title:'',
      description:'',
      imgSrc:img533,
  },
    {
      id:1,
      title:'',
      description:'',
      imgSrc:img534,
  },
    {
      id:1,
      title:'',
      description:'',
      imgSrc:img537,
  },
    {
      id:1,
      title:'',
      description:'',
      imgSrc:img538,
  },
    {
      id:1,
      title:'',
      description:'',
      imgSrc:img539,
  },
    {
      id:1,
      title:'',
      description:'',
      imgSrc:img540,
  },
    {
      id:1,
      title:'',
      description:'',
      imgSrc:img541,
  },
    {
      id:1,
      title:'',
      description:'',
      imgSrc:img542,
  },
    {
      id:1,
      title:'',
      description:'',
      imgSrc:img543,
  },
    {
      id:1,
      title:'',
      description:'',
      imgSrc:img544,
  },
    {
      id:1,
      title:'',
      description:'',
      imgSrc:img545,
  },
    {
      id:1,
      title:'',
      description:'',
      imgSrc:img546,
  },
    {
      id:1,
      title:'',
      description:'',
      imgSrc:img547,
  },
    {
      id:1,
      title:'',
      description:'',
      imgSrc:img548,
  },
    {
      id:1,
      title:'',
      description:'',
      imgSrc:img553,
  },
    {
      id:1,
      title:'',
      description:'',
      imgSrc:img566,
  },
    {
      id:1,
      title:'',
      description:'',
      imgSrc:img567,
  },
    {
      id:1,
      title:'',
      description:'',
      imgSrc:img568,
  },
    {
      id:1,
      title:'',
      description:'',
      imgSrc:img569,
  },
    {
      id:1,
      title:'',
      description:'',
      imgSrc:img570,
  },
    {
      id:1,
      title:'',
      description:'',
      imgSrc:img571,
  },
    {
      id:1,
      title:'',
      description:'',
      imgSrc:img572,
  },
    {
      id:1,
      title:'',
      description:'',
      imgSrc:img573,
  },
    {
      id:1,
      title:'',
      description:'',
      imgSrc:img574,
  },
    {
      id:1,
      title:'',
      description:'',
      imgSrc:img575,
  },
    {
      id:1,
      title:'',
      description:'',
      imgSrc:img576,
  },
    {
      id:1,
      title:'',
      description:'',
      imgSrc:img577,
  },
    {
      id:1,
      title:'',
      description:'',
      imgSrc:img578,
  },
    {
      id:1,
      title:'',
      description:'',
      imgSrc:img579,
  },
    {
      id:1,
      title:'',
      description:'',
      imgSrc:img580,
  },
    {
      id:1,
      title:'',
      description:'',
      imgSrc:img582,
  },
    {
      id:1,
      title:'',
      description:'',
      imgSrc:img585,
  },
    {
      id:1,
      title:'',
      description:'',
      imgSrc:img589,
  },
    {
      id:1,
      title:'',
      description:'',
      imgSrc:img590,
  },
  ]

  const [model, setModel] = useState(false);
  const [tempImgSrc, setTempImgSrc] = useState('')
  const [loading, setLoading] = useState(false)
  
  const shuffle = a => {
      for (let i = a.length; i; i--) {
          let j = Math.floor(Math.random() * i);
          [a[i - 1], a[j]] = [a[j], a[i - 1]];
      }
    }
    
    shuffle(imageData);
    
  useEffect(() => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    },5000)
  
  },[])
  
  const getImg = (imgSrc) => {
      setTempImgSrc(imgSrc);
      setModel(true);
  }

  return (
    <>
    {
      loading ? <div className='cliper-middle'><ClipLoader color={'#D0021B'} loading={loading} size={100} /></div>
      :
      <div className='all__main__container'>
            <div className={model? "model open":"model"}>
                <img src={tempImgSrc} alt="" />
                <CloseIcon onClick={() => setModel(false)}/>
            </div>
            <div className="gallery">
                {
                    imageData.map((item, index) => {
                        return(
                            <div className='pics' key={index} onClick={() => getImg(item.imgSrc)}>
                            <img src={item.imgSrc} style={{width:'100%'}} alt="" />
                            {/* <div className='image__overlay'>
                                <h4>{item.title}</h4>
                                <p>{item.description}</p>
                            </div> */}
                        </div>
                        )
                    })
                }
            </div>
        </div>
    }
      
    </>
  );
};

export default FloorPlan;
