import React, { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { TabTitle } from "../../GeneralFunction";

import './3dInterior.css';
import img1 from '../../../img/Interior Render pic/Aloffr20 3RD.jpg'
import img2 from '../../../img/Interior Render pic/Amit8171 Community space.jpg'
import img3 from '../../../img/Interior Render pic/Amit8171 GYM_1.jpg'
import img4 from '../../../img/Interior Render pic/Amit8171 GYM_2.jpg'
import img5 from '../../../img/Interior Render pic/Amit8171 kit+din.jpg'
import img6 from '../../../img/Interior Render pic/Amit8171 Pool table.jpg'
import img8 from '../../../img/Interior Render pic/Amit8171 Table Tenis.jpg'
import img9 from '../../../img/Interior Render pic/Amit8171 Theater_1.jpg'
import img10 from '../../../img/Interior Render pic/Amit8171 Theater_2.jpg'
import img13 from '../../../img/Interior Render pic/BeeHomes Bathroom.jpg'
import img18 from '../../../img/Interior Render pic/Bhangals 3.jpg'
import img19 from '../../../img/Interior Render pic/Bhangals 4.jpg'
import img20 from '../../../img/Interior Render pic/Blakedog 1st.jpg'
import img21 from '../../../img/Interior Render pic/Blakedog 2nd floor_1.jpg'
import img22 from '../../../img/Interior Render pic/Blakedog 2nd floor_2.jpg'
import img25 from '../../../img/Interior Render pic/Blakedog 2nd1.jpg'
import img26 from '../../../img/Interior Render pic/Blakedog 3rd.jpg'
import img27 from '../../../img/Interior Render pic/Blakedog 4th.jpg'
import img28 from '../../../img/Interior Render pic/Blakedog 5th.jpg'
import img29 from '../../../img/Interior Render pic/Blakedog INT_1.jpg'
import img30 from '../../../img/Interior Render pic/Blakedog INT_2.jpg'
import img36 from '../../../img/Interior Render pic/Ethanb.632         3rd floor_10.jpg'
import img37 from '../../../img/Interior Render pic/Ethanb.632         3rd floor_9.jpg'
import img38 from '../../../img/Interior Render pic/Ethanb.632        2nd floor_3.jpg'
import img39 from '../../../img/Interior Render pic/Golderkeygroup Bathroom_02.png'
import img44 from '../../../img/Interior Render pic/Jacalbarracin V_1b.jpg'
import img45 from '../../../img/Interior Render pic/Jacalbarracin V_1c.jpg'
import img48 from '../../../img/Interior Render pic/Jcalbarracin3_ 5th.jpg'
import img49 from '../../../img/Interior Render pic/Jcalbarracin3_ Interior_2.jpg'
import img50 from '../../../img/Interior Render pic/Jcalbarracin3_ Stair_1.jpg'
import img51 from '../../../img/Interior Render pic/Jcalbarracin3_1st.jpg'
import img53 from '../../../img/Interior Render pic/Joybooker View_1.jpg'
import img54 from '../../../img/Interior Render pic/Joybooker View_2.jpg'
import img56 from '../../../img/Interior Render pic/Joybooker View_4.jpg'
import img57 from '../../../img/Interior Render pic/Joybooker View_5.jpg'
import img59 from '../../../img/Interior Render pic/Kamal jay suraya Roof top 3.jpg'
import img60 from '../../../img/Interior Render pic/Kaliesha kitchen2nd.jpg'
import img61 from '../../../img/Interior Render pic/Kaliesha kitchen1st.jpg'
import img62 from '../../../img/Interior Render pic/Kitchen  View_2.jpg'
import img63 from '../../../img/Interior Render pic/Kitchen  View_3.jpg'
import img64 from '../../../img/Interior Render pic/Mccormickteam 01.jpg'
import img67 from '../../../img/Interior Render pic/Mccormickteam 04.jpg'
import img69 from '../../../img/Interior Render pic/Mccormickteam 06.jpg'
import img70 from '../../../img/Interior Render pic/Mccormickteam bath-2.jpg'
import img71 from '../../../img/Interior Render pic/Mccormickteam Bath_1.jpg'
import img72 from '../../../img/Interior Render pic/Mccormickteam Bath_3.jpg'
import img74 from '../../../img/Interior Render pic/Mccormickteam Bed_3.jpg'
import img75 from '../../../img/Interior Render pic/Mccormickteam Bed_3.jpg'
import img79 from '../../../img/Interior Render pic/Neadie GROUND FLOOR_BED 1_V2.jpg'
import img83 from '../../../img/Interior Render pic/Office_1.jpg'
import img84 from '../../../img/Interior Render pic/Office_2.jpg'
import img85 from '../../../img/Interior Render pic/Office_3.jpg'
import img86 from '../../../img/Interior Render pic/Office_4.jpg'
import img87 from '../../../img/Interior Render pic/Office_5.jpg'
import img88 from '../../../img/Interior Render pic/Pamma83 Toilet2_1st floor.jpg'
import img89 from '../../../img/Interior Render pic/Pamma83 Utility_ground floor.jpg'
import img97 from '../../../img/Interior Render pic/Rankgalea 1st.jpg'
import img98 from '../../../img/Interior Render pic/Rankgalea 2nd.jpg'
import img99 from '../../../img/Interior Render pic/Rankgalea 3rd.jpg'
import img100 from '../../../img/Interior Render pic/Rankgalea 4th.jpg'
import img101 from '../../../img/Interior Render pic/Rankgalea 5th.jpg'
import img102 from '../../../img/Interior Render pic/Rankgalea 6th.jpg'
import img103 from '../../../img/Interior Render pic/Rankgalea 7th.jpg'
import img104 from '../../../img/Interior Render pic/Rankgalea L shape .jpg'
import img109 from '../../../img/Interior Render pic/Resulhan 15151515.jpg'
import img110 from '../../../img/Interior Render pic/Resulhan 22.jpg'
import img116 from '../../../img/Interior Render pic/Resulhan 666.jpg'
import img117 from '../../../img/Interior Render pic/Resulhan t-1-ggg.jpg'
import img125 from '../../../img/Interior Render pic/Rui Yamaguchi 5th view222.jpg'
import img126 from '../../../img/Interior Render pic/Rui Yamaguchi 6th view2222.jpg'
import img130 from '../../../img/Interior Render pic/Sinthusen 2 Kit_2.jpg'
import img131 from '../../../img/Interior Render pic/Sinthusen 2 Living_1.jpg'
import img133 from '../../../img/Interior Render pic/Sinthusen 2 Toilet_1.jpg'
import img149 from '../../../img/Interior Render pic/Taypty Kitchen_m1.jpg'
import img150 from '../../../img/Interior Render pic/Taypty Living_1.jpg'
import img153 from '../../../img/Interior Render pic/Taypty Master Bed_1.jpg'
import img158 from '../../../img/Aredhouse/BED ROOM_1.jpg'
import img159 from '../../../img/Aredhouse/Bedroom_2.jpg'
import img160 from '../../../img/Aredhouse/Living_2.jpg'
import img161 from '../../../img/Aredhouse/New folder/Dining_2.jpg'
import img162 from '../../../img/Aredhouse/New folder/Dining_3.jpg'
import img163 from '../../../img/Aredhouse/New folder/Kitchen_1.jpg'
import img164 from '../../../img/Aredhouse/New folder/Living_2Final.jpg'
import img165 from '../../../img/Aredhouse/New folder/Toilet_1.jpg'
import img166 from '../../../img/Aredhouse/New folder/Toilet_2.jpg'
import img167 from '../../../img/Creative Home design/Dining.jpg'
import img172 from '../../../img/isiimmobilien/BEDROOM_54.jpg'
import img173 from '../../../img/isiimmobilien/Kitchen.jpg'
import img174 from '../../../img/isiimmobilien/Unit2_Kitchen.jpg'
import img175 from '../../../img/isiimmobilien/Unit4_BED1.jpg'
import img176 from '../../../img/Jay Verma/@ Living & Dining.jpg'
import img177 from '../../../img/Jay Verma/Bed_1.jpg'
import img178 from '../../../img/Jay Verma/Living_1.jpg'
import img179 from '../../../img/Jay Verma/Living_3.jpg'
import img183 from '../../../img/John Figuerado Jr  JWF Design/Dining_A.jpg'
import img184 from '../../../img/John Figuerado Jr  JWF Design/Iso_toilet.jpg'
import img185 from '../../../img/John Figuerado Jr  JWF Design/Kitchen&Dining_A.jpg'
import img186 from '../../../img/John Figuerado Jr  JWF Design/Kitchen_A.jpg'
import img188 from '../../../img/Lewiskeating/Unit2_Kitchen.jpg'
import img190 from '../../../img/Lewiskeating/Unit2_Toilet.jpg'
import img192 from '../../../img/Matthew/1st Floor dining.jpg'
import img193 from '../../../img/Matthew/1st Floor kitchen.jpg'
import img194 from '../../../img/Matthew/1st Floor Master bed.jpg'
import img196 from '../../../img/Matthew/1st floor_Game1.jpg'
import img197 from '../../../img/Matthew/1st floor_Game2.jpg'
import img198 from '../../../img/Matthew/1st floor_toilet2.jpg'
import img199 from '../../../img/Matthew/Basement Dining.jpg'
import img200 from '../../../img/Matthew/Basement Kitchen.jpg'
import img201 from '../../../img/Matthew/Basement Living.jpg'
import img202 from '../../../img/Matthew/Basement Masterbed.jpg'
import img203 from '../../../img/Matthew/Basement Toilet.jpg'
import img204 from '../../../img/Matthew/Basement Utility.jpg'
import img205 from '../../../img/Matthew/Basement_Master Bed1.jpg'
import img207 from '../../../img/Matthew/Ground_ Toilet1.jpg'
import img208 from '../../../img/Matthew/Ground_Bed1.jpg'
import img209 from '../../../img/Matthew/Ground_Kitchen.jpg'
import img210 from '../../../img/Matthew/Ground_Kitchen2.jpg'
import img211 from '../../../img/Matthew/Ground_Master Bed1.jpg'
import img212 from '../../../img/Matthew/Ground_Utility.jpg'
import img215 from '../../../img/New folder/1st floor_ BedB.jpg'
import img216 from '../../../img/New folder/1st floor_loft2.jpg'
import img217 from '../../../img/New folder/1st floor_Master Bed.jpg'
import img218 from '../../../img/New folder/1st floor_Master Bed2.jpg'
import img219 from '../../../img/New folder/1st floor_MasterToilet1B.jpg'
import img220 from '../../../img/New folder/Ground Floor_Dining.jpg'
import img221 from '../../../img/New folder/Ground Floor_Living.jpg'
import img222 from '../../../img/New folder/Ground Floor_staircase.jpg'

import ClipLoader from "react-spinners/ClipLoader";


const Interior = () => {
  TabTitle("3d-Interior - Arch Pixel");

  let imageData = [
        
    {
        id:1,
        title:'',
        description:'',
        imgSrc:img1,
    },
    {
        id:1,
        title:'',
        description:'',
        imgSrc:img2,
    },
    {
        id:1,
        title:'',
        description:'',
        imgSrc:img3,
    },
    {
        id:1,
        title:'',
        description:'',
        imgSrc:img4,
    },
    {
        id:1,
        title:'',
        description:'',
        imgSrc:img5,
    },
    {
        id:1,
        title:'',
        description:'',
        imgSrc:img6,
    },
    {
        id:1,
        title:'',
        description:'',
        imgSrc:img6,
    },
    {
        id:1,
        title:'',
        description:'',
        imgSrc:img8,
    },
    {
        id:1,
        title:'',
        description:'',
        imgSrc:img9,
    },
    {
        id:1,
        title:'',
        description:'',
        imgSrc:img10,
    },
    {
        id:1,
        title:'',
        description:'',
        imgSrc:img13,
    },
    {
        id:1,
        title:'',
        description:'',
        imgSrc:img18,
    },
    {
        id:1,
        title:'',
        description:'',
        imgSrc:img19,
    },
    {
        id:1,
        title:'',
        description:'',
        imgSrc:img20,
    },
    {
        id:1,
        title:'',
        description:'',
        imgSrc:img21,
    },
    {
        id:1,
        title:'',
        description:'',
        imgSrc:img22,
    },
    {
        id:1,
        title:'',
        description:'',
        imgSrc:img25,
    },
    {
        id:1,
        title:'',
        description:'',
        imgSrc:img26,
    },
    {
        id:1,
        title:'',
        description:'',
        imgSrc:img27,
    },
    {
        id:1,
        title:'',
        description:'',
        imgSrc:img28,
    },
    {
        id:1,
        title:'',
        description:'',
        imgSrc:img29,
    },
    {
        id:1,
        title:'',
        description:'',
        imgSrc:img30,
    },
    {
        id:1,
        title:'',
        description:'',
        imgSrc:img36,
    },
    {
        id:1,
        title:'',
        description:'',
        imgSrc:img37,
    },
    {
        id:1,
        title:'',
        description:'',
        imgSrc:img38,
    },
    {
        id:1,
        title:'',
        description:'',
        imgSrc:img39,
    },
    {
        id:1,
        title:'',
        description:'',
        imgSrc:img44,
    },
    {
        id:1,
        title:'',
        description:'',
        imgSrc:img45,
    },
    {
        id:1,
        title:'',
        description:'',
        imgSrc:img48,
    },
    {
        id:1,
        title:'',
        description:'',
        imgSrc:img49,
    },
    {
        id:1,
        title:'',
        description:'',
        imgSrc:img50,
    },
    {
        id:1,
        title:'',
        description:'',
        imgSrc:img51,
    },
    {
        id:1,
        title:'',
        description:'',
        imgSrc:img53,
    },
    {
        id:1,
        title:'',
        description:'',
        imgSrc:img54,
    },
    {
        id:1,
        title:'',
        description:'',
        imgSrc:img56,
    },
    {
        id:1,
        title:'',
        description:'',
        imgSrc:img57,
    },
    {
        id:1,
        title:'',
        description:'',
        imgSrc:img59,
    },
    {
        id:1,
        title:'',
        description:'',
        imgSrc:img60,
    },
    {
        id:1,
        title:'',
        description:'',
        imgSrc:img61,
    },
    {
        id:1,
        title:'',
        description:'',
        imgSrc:img62,
    },
    {
        id:1,
        title:'',
        description:'',
        imgSrc:img63,
    },
    {
        id:1,
        title:'',
        description:'',
        imgSrc:img64,
    },
    {
        id:1,
        title:'',
        description:'',
        imgSrc:img67,
    },
    {
        id:1,
        title:'',
        description:'',
        imgSrc:img69,
    },
    {
        id:1,
        title:'',
        description:'',
        imgSrc:img70,
    },
    {
        id:1,
        title:'',
        description:'',
        imgSrc:img71,
    },
    {
        id:1,
        title:'',
        description:'',
        imgSrc:img72,
    },
    {
        id:1,
        title:'',
        description:'',
        imgSrc:img74,
    },
    {
        id:1,
        title:'',
        description:'',
        imgSrc:img75,
    },
    {
        id:1,
        title:'',
        description:'',
        imgSrc:img79,
    },
    {
        id:1,
        title:'',
        description:'',
        imgSrc:img83,
    },
    {
        id:1,
        title:'',
        description:'',
        imgSrc:img84,
    },
    {
        id:1,
        title:'',
        description:'',
        imgSrc:img85,
    },
    {
        id:1,
        title:'',
        description:'',
        imgSrc:img86,
    },
    {
        id:1,
        title:'',
        description:'',
        imgSrc:img87,
    },
    {
        id:1,
        title:'',
        description:'',
        imgSrc:img88,
    },
    {
        id:1,
        title:'',
        description:'',
        imgSrc:img89,
    },
    {
        id:1,
        title:'',
        description:'',
        imgSrc:img97,
    },
    {
        id:1,
        title:'',
        description:'',
        imgSrc:img98,
    },
    {
        id:1,
        title:'',
        description:'',
        imgSrc:img99,
    },
    {
        id:1,
        title:'',
        description:'',
        imgSrc:img100,
    },
    {
        id:1,
        title:'',
        description:'',
        imgSrc:img101,
    },
    {
        id:1,
        title:'',
        description:'',
        imgSrc:img102,
    },
    {
        id:1,
        title:'',
        description:'',
        imgSrc:img103,
    },
    {
        id:1,
        title:'',
        description:'',
        imgSrc:img104,
    },
    {
        id:1,
        title:'',
        description:'',
        imgSrc:img109,
    },
    {
        id:1,
        title:'',
        description:'',
        imgSrc:img110,
    },
    {
        id:1,
        title:'',
        description:'',
        imgSrc:img116,
    },
    {
        id:1,
        title:'',
        description:'',
        imgSrc:img117,
    },
    {
        id:1,
        title:'',
        description:'',
        imgSrc:img125,
    },
    {
        id:1,
        title:'',
        description:'',
        imgSrc:img126,
    },
    {
        id:1,
        title:'',
        description:'',
        imgSrc:img130,
    },
    {
        id:1,
        title:'',
        description:'',
        imgSrc:img131,
    },
    {
        id:1,
        title:'',
        description:'',
        imgSrc:img133,
    },
    
    {
        id:1,
        title:'',
        description:'',
        imgSrc:img149,
    },
    
    {
        id:1,
        title:'',
        description:'',
        imgSrc:img150,
    },
    
    {
        id:1,
        title:'',
        description:'',
        imgSrc:img153,
    },
    {
        id:1,
        title:'',
        description:'',
        imgSrc:img158,
    },
    {
        id:1,
        title:'',
        description:'',
        imgSrc:img159,
    },
    {
        id:1,
        title:'',
        description:'',
        imgSrc:img160,
    },
    {
        id:1,
        title:'',
        description:'',
        imgSrc:img161,
    },
    {
        id:1,
        title:'',
        description:'',
        imgSrc:img162,
    },
    {
        id:1,
        title:'',
        description:'',
        imgSrc:img163,
    },
    {
        id:1,
        title:'',
        description:'',
        imgSrc:img164,
    },
    {
        id:1,
        title:'',
        description:'',
        imgSrc:img165,
    },
    {
        id:1,
        title:'',
        description:'',
        imgSrc:img166,
    },
    {
        id:1,
        title:'',
        description:'',
        imgSrc:img167,
    },
    {
        id:1,
        title:'',
        description:'',
        imgSrc:img172,
    },
    {
        id:1,
        title:'',
        description:'',
        imgSrc:img173,
    },
    {
        id:1,
        title:'',
        description:'',
        imgSrc:img174,
    },
    {
        id:1,
        title:'',
        description:'',
        imgSrc:img175,
    },
    {
        id:1,
        title:'',
        description:'',
        imgSrc:img176,
    },
    {
        id:1,
        title:'',
        description:'',
        imgSrc:img177,
    },
    {
        id:1,
        title:'',
        description:'',
        imgSrc:img178,
    },
    {
        id:1,
        title:'',
        description:'',
        imgSrc:img179,
    },
    {
        id:1,
        title:'',
        description:'',
        imgSrc:img183,
    },
    {
        id:1,
        title:'',
        description:'',
        imgSrc:img184,
    },
    {
        id:1,
        title:'',
        description:'',
        imgSrc:img185,
    },
    {
        id:1,
        title:'',
        description:'',
        imgSrc:img186,
    },
    {
        id:1,
        title:'',
        description:'',
        imgSrc:img188,
    },
    {
        id:1,
        title:'',
        description:'',
        imgSrc:img190,
    },
    {
        id:1,
        title:'',
        description:'',
        imgSrc:img192,
    },
    {
        id:1,
        title:'',
        description:'',
        imgSrc:img193,
    },
    {
        id:1,
        title:'',
        description:'',
        imgSrc:img194,
    },
    {
        id:1,
        title:'',
        description:'',
        imgSrc:img196,
    },
    {
        id:1,
        title:'',
        description:'',
        imgSrc:img197,
    },
    {
        id:1,
        title:'',
        description:'',
        imgSrc:img198,
    },
    {
        id:1,
        title:'',
        description:'',
        imgSrc:img199,
    },
    {
        id:1,
        title:'',
        description:'',
        imgSrc:img200,
    },
    {
        id:1,
        title:'',
        description:'',
        imgSrc:img201,
    },
    {
        id:1,
        title:'',
        description:'',
        imgSrc:img202,
    },
    {
        id:1,
        title:'',
        description:'',
        imgSrc:img203,
    },
    {
        id:1,
        title:'',
        description:'',
        imgSrc:img204,
    },
    {
        id:1,
        title:'',
        description:'',
        imgSrc:img205,
    },
    {
        id:1,
        title:'',
        description:'',
        imgSrc:img207,
    },
    {
        id:1,
        title:'',
        description:'',
        imgSrc:img208,
    },
    {
        id:1,
        title:'',
        description:'',
        imgSrc:img209,
    },
    {
        id:1,
        title:'',
        description:'',
        imgSrc:img210,
    },
    {
        id:1,
        title:'',
        description:'',
        imgSrc:img211,
    },
    {
        id:1,
        title:'',
        description:'',
        imgSrc:img212,
    },
    {
        id:1,
        title:'',
        description:'',
        imgSrc:img215,
    },
    {
        id:1,
        title:'',
        description:'',
        imgSrc:img216,
    },
    {
        id:1,
        title:'',
        description:'',
        imgSrc:img217,
    },
    {
        id:1,
        title:'',
        description:'',
        imgSrc:img218,
    },
    {
        id:1,
        title:'',
        description:'',
        imgSrc:img219,
    },
    {
        id:1,
        title:'',
        description:'',
        imgSrc:img220,
    },
    {
        id:1,
        title:'',
        description:'',
        imgSrc:img221,
    },
    {
        id:1,
        title:'',
        description:'',
        imgSrc:img222,
    },
    
]

const [model, setModel] = useState(false);
const [tempImgSrc, setTempImgSrc] = useState('')
const [loading, setLoading] = useState(false)

const shuffle = a => {
    for (let i = a.length; i; i--) {
        let j = Math.floor(Math.random() * i);
        [a[i - 1], a[j]] = [a[j], a[i - 1]];
    }
  }
  
  shuffle(imageData);
  
useEffect(() => {
  setLoading(true)
  setTimeout(() => {
    setLoading(false)
  },5000)

},[])

const getImg = (imgSrc) => {
    setTempImgSrc(imgSrc);
    setModel(true);
}


  return (
    <>
    {
      loading ? <div className='cliper-middle'><ClipLoader color={'#D0021B'} loading={loading} size={100} /></div>
      :
      <div className='all__main__container'>
            <div className={model? "model open":"model"}>
                <img src={tempImgSrc} alt="" />
                <CloseIcon onClick={() => setModel(false)}/>
            </div>
            <div className="gallery">
                {
                    imageData.map((item, index) => {
                        return(
                            <div className='pics' key={index} onClick={() => getImg(item.imgSrc)}>
                            <img src={item.imgSrc} style={{width:'100%'}} alt="" />
                            {/* <div className='image__overlay'>
                                <h4>{item.title}</h4>
                                <p>{item.description}</p>
                            </div> */}
                        </div>
                        )
                    })
                }
            </div>
        </div>
    }
      
    </>
  );
};

export default Interior;
